import thumbnailsTestimonials from '@griddo-images/thumbnails/Testimonials.png'

export default {
  schemaType: 'module',
  displayName: 'Testimonials',
  component: 'Testimonials',
  category: 'people',
  dataPacks: ['TESTIMONIALS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'TESTIMONIALS' }],
          key: 'data',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'Testimonials',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData:'TESTIMONIALS' }],
      order: 'recent',
      quantity: 10,
    },
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsTestimonials,
    '2x': thumbnailsTestimonials,
  },
}
