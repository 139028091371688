import thumbnailsProjectsDistributor from '@griddo-images/thumbnails/ProjectsDistributor.png'

export default {
  schemaType: 'module',
  displayName: 'Projects Distributor',
  component: 'ProjectsDistributor',
  category: 'research',
  dataPacks: ['PROJECTS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'PROJECTS' }],
          key: 'data',
          fullRelations: true,
          mandatory: true,
        },
        {
          title: 'Items per page',
          type: 'NumberField',
          key: 'itemsPerPage',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Distributor type',
          key: 'distributorType',
          type: 'ConditionalField',
          options: [
            {
              name: 'expandable',
              value: 'expandable',
              title: 'Expandable',
            },
            {
              name: 'fully',
              value: 'fully',
              title: 'Fully Visible Distributor',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'ProjectsDistributor',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData: 'PROJECTS' }],
      order: 'recent',
      quantity: 10,
    },
    distributorType: 'expandable',
    itemsPerPage: 10,
  },

  thumbnails: {
    '1x': thumbnailsProjectsDistributor,
    '2x': thumbnailsProjectsDistributor,
  },
}
