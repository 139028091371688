export default {
  schemaType: 'component',
  displayName: 'Program Table Column',
  component: 'ProgramTableColumn',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
          mandatory: true,
        },
        {
          title: 'Table Row',
          type: 'ComponentArray',
          whiteList: ['ProgramTableRow'],
          key: 'elements',
          mandatory: true,
          maxItems: null,
        },
      ],
    },
  ],

  default: {
    component: 'ProgramTableColumn',
    title: 'Nº Semestre',
    elements: [
      {
        component: 'ProgramTableRow',
        title: 'Asignatura',
        credits: '6 ECTS',
        type: 'Obligatoria',
      },
    ],
  },
}
