import thumbnailsRequestInfoForm from '@griddo-images/thumbnails/RequestInfoForm.png'

import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'primary01'
defaultBtn.buttonText = 'Solicita información'

export default {
  schemaType: 'component',
  displayName: 'Request Info Form',
  component: 'BasicMoreInfo',
  dataPacks: null,
  category: 'content',
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'titleRequest',
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          humanReadable: true,
          key: 'subtitle',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'TextField',
          humanReadable: true,
          key: 'description',
          hideable: true,
        },
        {
          title: 'Form Type',
          type: 'RadioGroup',
          key: 'formType',
          options: [
            {
              title: 'Corto Nacional',
              name: 'Corto Nacional',
              value: '01',
            },
            {
              title: 'Corto Multi Nacional',
              name: 'Corto Multi Nacional',
              value: '02',
            },
            {
              title: 'Largo Nac e INT',
              name: 'Largo Nac e INT',
              value: '03',
            },
            {
              title: 'Largo Multi Nac e INT',
              name: 'Largo Multi Nac e INT',
              value: '04',
            },
            {
              title: 'Largo Home',
              name: 'Largo Home',
              value: '05',
            },
          ],
          mandatory: true,
        },
        {
          title: 'CTA',
          type: 'TextField',
          humanReadable: true,
          key: 'buttonText',
          mandatory: true,
        },
        {
          title: 'Program List',
          type: 'ReferenceField',
          sources: [{ structuredData: 'PROGRAMS' }],
          key: 'data',
          mandatory: true,
        },
        {
          title: 'Submit button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: false,
        },
      ],
    },
  ],

  default: {
    component: 'BasicMoreInfo',
    titleRequest: '¿Hablamos?',
    subtitle: 'Cuéntanos sobre ti.',
    description:
      'Si tienes dudas escríbenos y nos pondremos en contacto contigo.',
    buttonText: 'Solicita Información',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData: 'PROGRAMS' }],
      order: 'recent',
    },
    formType: '05',
    button: { ...defaultBtn },
  },

  thumbnails: {
    '1x': thumbnailsRequestInfoForm,
    '2x': thumbnailsRequestInfoForm,
  },
}
