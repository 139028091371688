import * as React from 'react'
import { Component as GriddoComponent } from '@griddo/core'

const AccordionCollection = React.lazy(() =>
  import(
    './AccordionCollection' /* webpackChunkName: "module---src-AccordionCollection-js" */
    ),
)
const BasicContent = React.lazy(() =>
  import(
    './BasicContent' /* webpackChunkName: "module---src-BasicContent-js" */
    ),
)
const BasicText = React.lazy(() =>
  import('./BasicText' /* webpackChunkName: "module---src-BasicText-js" */),
)
const CardCollection = React.lazy(() =>
  import(
    './CardCollection' /* webpackChunkName: "module---src-CardCollection-js" */
    ),
)
const ColumnText = React.lazy(() =>
  import('./ColumnText' /* webpackChunkName: "module---src-ColumnText-js" */),
)
const CypherCollection = React.lazy(() =>
  import(
    './CypherCollection' /* webpackChunkName: "module---src-CypherCollection-js" */
    ),
)
const DownloadCollection = React.lazy(() =>
  import(
    './DownloadCollection' /* webpackChunkName: "module---src-DownloadCollection-js" */
    ),
)

const Footer = React.lazy(() =>
  import('./Footer' /* webpackChunkName: "module---src-Footer-js" */),
)

const LandingFooter = React.lazy(() =>
  import(
    './LandingFooter' /* webpackChunkName: "module---src-LandingFooter-js" */
    ),
)

//import Header from './Header'

const Header = React.lazy(() =>
  import('./Header' /* webpackChunkName: "module---src-Header-js" */),
)

const HeroCompact = React.lazy(() =>
  import('./HeroCompact' /* webpackChunkName: "module---src-HeroCompact-js" */),
)

const MultimediaContent = React.lazy(() =>
  import(
    './MultimediaContent' /* webpackChunkName: "module---src-MultimediaContent-js" */
    ),
)
const Spacer = React.lazy(() =>
  import('./Spacer' /* webpackChunkName: "module---src-Spacer-js" */),
)
const StudyPlan = React.lazy(() =>
  import('./StudyPlan' /* webpackChunkName: "module---src-StudyPlan-js" */),
)
const LandingStudyPlan = React.lazy(() =>
  import(
    './LandingStudyPlan' /* webpackChunkName: "module---src-LandingStudyPlan-js" */
    ),
)
const Testimonials = React.lazy(() =>
  import(
    './Testimonials' /* webpackChunkName: "module---src-Testimonials-js" */
    ),
)
const HighlightedContent = React.lazy(() =>
  import(
    './HighlightedContent' /* webpackChunkName: "module---src-HighlightedContent-js" */
    ),
)
const FeaturedBlock = React.lazy(() =>
  import(
    './FeaturedBlock' /* webpackChunkName: "module---src-FeaturedBlock-js" */
    ),
)
const FeaturedAudiovisual = React.lazy(() =>
  import(
    './FeaturedAudiovisual' /* webpackChunkName: "module---src-FeaturedAudiovisual-js" */
    ),
)
const GoToTop = React.lazy(() =>
  import('./GoToTop' /* webpackChunkName: "module---src-GoToTop-js" */),
)
const HorizontalTabs = React.lazy(() =>
  import(
    './HorizontalTabs' /* webpackChunkName: "module---src-HorizontalTabs-js" */
    ),
)
const CarouselCollection = React.lazy(() =>
  import(
    './CarouselCollection' /* webpackChunkName: "module---src-CarouselCollection-js" */
    ),
)
const VerticalTabs = React.lazy(() =>
  import(
    './VerticalTabs' /* webpackChunkName: "module---src-VerticalTabs-js" */
    ),
)
const ProgramTabs = React.lazy(() =>
  import('./ProgramTabs' /* webpackChunkName: "module---src-ProgramTabs-js" */),
)
const ColumnsPresentation = React.lazy(() =>
  import(
    './ColumnsPresentation' /* webpackChunkName: "module---src-ColumnsPresentation-js" */
    ),
)
const MixedCollection = React.lazy(() =>
  import(
    './MixedCollection' /* webpackChunkName: "module---src-MixedCollection-js" */
    ),
)
const HeroCarousel = React.lazy(() =>
  import(
    './HeroCarousel' /* webpackChunkName: "module---src-HeroCarousel-js" */
    ),
)
const ProgramsDistributor = React.lazy(() =>
  import(
    './ProgramsDistributor' /* webpackChunkName: "module---src-ProgramsDistributor-js" */
    ),
)
const LogoCollection = React.lazy(() =>
  import(
    './LogoCollection' /* webpackChunkName: "module---src-LogoCollection-js" */
    ),
)
const Grid = React.lazy(() =>
  import('./Grid' /* webpackChunkName: "module---src-Grid-js" */),
)

const MoreInfoForm = React.lazy(() =>
  import(
    './MoreInfoForm' /* webpackChunkName: "module---src-MoreInfoForm-js" */
    ),
)

//import MoreInfoForm from './MoreInfoForm'

const RequestInfoForm = React.lazy(() =>
  import(
    './RequestInfoForm' /* webpackChunkName: "module---src-RequestInfoForm-js" */
    ),
)
const NewsletterForm = React.lazy(() =>
  import(
    './NewsletterForm' /* webpackChunkName: "module---src-NewsletterForm-js" */
    ),
)
const DownloadForm = React.lazy(() =>
  import(
    './DownloadForm' /* webpackChunkName: "module---src-DownloadForm-js" */
    ),
)
const EventForm = React.lazy(() =>
  import('./EventForm' /* webpackChunkName: "module---src-EventForm-js" */),
)
const PrivacyForm = React.lazy(() =>
  import('./PrivacyForm' /* webpackChunkName: "module---src-PrivacyForm-js" */),
)
const SocialShare = React.lazy(() =>
  import('./SocialShare' /* webpackChunkName: "module---src-SocialShare-js" */),
)
const NewsDistributor = React.lazy(() =>
  import(
    './NewsDistributor' /* webpackChunkName: "module---src-NewsDistributor-js" */
    ),
)
const StaffDistributor = React.lazy(() =>
  import(
    './StaffDistributor' /* webpackChunkName: "module---src-StaffDistributor-js" */
    ),
)
const StaffDistributorSimple = React.lazy(() =>
  import(
    './StaffDistributorSimple' /* webpackChunkName: "module---src-StaffDistributorSimple-js" */
    ),
)
const CardsDistributor = React.lazy(() =>
  import(
    '@modules/CardsDistributor' /* webpackChunkName: "module---src-CardsDistributor-js" */
    ),
)
const SimpleHero = React.lazy(() =>
  import(
    '@modules/SimpleHero' /* webpackChunkName: "module---src-SimpleHero-js" */
    ),
)
const ContactForm = React.lazy(() =>
  import(
    '@modules/ContactForm' /* webpackChunkName: "module---src-ContactForm-js" */
    ),
)
const IntroForm = React.lazy(() =>
  import(
    '@modules/IntroForm' /* webpackChunkName: "module---src-IntroForm-js" */
    ),
)
const SpeakersCollection = React.lazy(() =>
  import(
    './SpeakersCollection' /* webpackChunkName: "module---src-SpeakersCollection-js" */
    ),
)
const EventDistributor = React.lazy(() =>
  import(
    './EventDistributor' /* webpackChunkName: "module---src-EventDistributor-js" */
    ),
)
const SearchBox = React.lazy(() =>
  import('./SearchBox' /* webpackChunkName: "module---src-SearchBox-js" */),
)
const InteractiveBasicContent = React.lazy(() =>
  import(
    './InteractiveBasicContent' /* webpackChunkName: "module---src-InteractiveBasicContent-js" */
    ),
)
const ReportsDistributor = React.lazy(() =>
  import(
    './ReportsDistributor' /* webpackChunkName: "module---src-ReportsDistributor-js" */
    ),
)
const ColouredCollection = React.lazy(() =>
  import(
    './ColouredCollection' /* webpackChunkName: "module---src-ColouredCollection-js" */
    ),
)
const GroupsDistributor = React.lazy(() =>
  import(
    './GroupsDistributor' /* webpackChunkName: "module---src-GroupsDistributor-js" */
    ),
)
const ProjectsDistributor = React.lazy(() =>
  import(
    './ProjectsDistributor' /* webpackChunkName: "module---src-ProjectsDistributor-js" */
    ),
)
const ProgramFilter = React.lazy(() =>
  import(
    './ProgramFilter' /* webpackChunkName: "module---src-ProgramFilter-js" */
    ),
)

const HeroProgram = React.lazy(() =>
  import('./HeroProgram' /* webpackChunkName: "module---src-HeroProgram-js" */),
)

const BasicMoreInfo = React.lazy(() =>
  import(
    './BasicMoreInfo' /* webpackChunkName: "module---src-BasicMoreInfo-js" */
    ),
)

const FAQCollection = React.lazy(() =>
  import(
    './FAQCollection' /* webpackChunkName: "module---src-FAQCollection-js" */
    ),
)

const BlogDistributor = React.lazy(() =>
  import(
    './BlogDistributor' /* webpackChunkName: "module---src-BlogDistributor-js" */
    ),
)

const LandingHeroProgram = React.lazy(() =>
  import(
    './LandingHeroProgram' /* webpackChunkName: "module---src-LandingHeroProgram-js" */
    ),
)
const LandingProgramTabs = React.lazy(() =>
  import(
    './LandingProgramTabs' /* webpackChunkName: "module---src-LandingProgramTabs-js" */
    ),
)

const LandingProgramTabLinks = React.lazy(() =>
  import(
    './LandingProgramTabLinks' /* webpackChunkName: "module---src-LandingProgramTabLinks-js" */
    ),
)

const LandingProgramCollection = React.lazy(() =>
  import(
    './LandingProgramCollection' /* webpackChunkName: "module---src-LandingProgramCollection-js" */
    ),
)
const LandingHeader = React.lazy(() =>
  import(
    './LandingHeader' /* webpackChunkName: "module---src-LandingHeader-js" */
    ),
)

const modules = {
  AccordionCollection,
  BasicContent,
  BasicText,
  CardCollection,
  ColumnText,
  CypherCollection,
  DownloadCollection,
  Footer,
  Header,
  HeroCompact,
  HeroProgram,
  MultimediaContent,
  Spacer,
  StudyPlan,
  Testimonials,
  HighlightedContent,
  FeaturedBlock,
  FeaturedAudiovisual,
  GoToTop,
  HorizontalTabs,
  CarouselCollection,
  VerticalTabs,
  ProgramTabs,
  ColumnsPresentation,
  MixedCollection,
  HeroCarousel,
  ProgramsDistributor,
  LogoCollection,
  Grid,
  MoreInfoForm,
  RequestInfoForm,
  NewsletterForm,
  DownloadForm,
  EventForm,
  PrivacyForm,
  SocialShare,
  NewsDistributor,
  CardsDistributor,
  StaffDistributor,
  StaffDistributorSimple,
  SimpleHero,
  ContactForm,
  IntroForm,
  SpeakersCollection,
  EventDistributor,
  SearchBox,
  InteractiveBasicContent,
  ReportsDistributor,
  ColouredCollection,
  GroupsDistributor,
  ProjectsDistributor,
  ProgramFilter,
  BasicMoreInfo,
  FAQCollection,
  BlogDistributor,
  LandingHeroProgram,

  LandingHeader,
  LandingFooter,
  LandingProgramTabs,
  LandingProgramTabLinks,
  LandingProgramCollection,
  LandingStudyPlan,
}

function Module(props) {
  //  if (props.component === 'Header') {
  //    return <GriddoComponent libComponents={modules} {...props} />
  //  }

  return (
    //    <React.Suspense fallback={null}>
    <GriddoComponent libComponents={modules} {...props} />
    //    </React.Suspense>
  )
}

export default modules

export {
  AccordionCollection,
  BasicContent,
  BasicText,
  CardCollection,
  ColumnText,
  CypherCollection,
  DownloadCollection,
  Footer,
  Header,
  HeroCompact,
  HeroProgram,
  MultimediaContent,
  Spacer,
  StudyPlan,
  Testimonials,
  HighlightedContent,
  FeaturedBlock,
  FeaturedAudiovisual,
  GoToTop,
  HorizontalTabs,
  CarouselCollection,
  VerticalTabs,
  ProgramTabs,
  ColumnsPresentation,
  MixedCollection,
  HeroCarousel,
  ProgramsDistributor,
  LogoCollection,
  Grid,
  MoreInfoForm,
  RequestInfoForm,
  NewsletterForm,
  DownloadForm,
  EventForm,
  PrivacyForm,
  SocialShare,
  NewsDistributor,
  CardsDistributor,
  StaffDistributor,
  StaffDistributorSimple,
  SimpleHero,
  ContactForm,
  IntroForm,
  SpeakersCollection,
  EventDistributor,
  SearchBox,
  InteractiveBasicContent,
  ReportsDistributor,
  ColouredCollection,
  GroupsDistributor,
  ProjectsDistributor,
  ProgramFilter,
  BasicMoreInfo,
  FAQCollection,
  BlogDistributor,
  LandingHeroProgram,

  LandingHeader,
  LandingFooter,
  LandingProgramTabs,
  LandingProgramTabLinks,
  LandingProgramCollection,
  LandingStudyPlan,
}


export { Module }
