export default {
  clientId: 'client-id',

  themes: [
    {
      name: 'UCMA theme',
      id: 'ucma',
      subthemes: [
        'light',
        'blue',
        'yellow',
        'ultraLightYellow',
        'ultraLightBlue',
        'lightBlue',
      ],
    },
  ],
}
