const eventCategories = {
  langs: [
    {
      label: 'Castellano',
      iconName: 'es',
    },
    {
      label: 'Francés',
      iconName: 'fr',
    },
    {
      label: 'Inglés',
      iconName: 'eng',
    },
    {
      label: 'Catalán',
      iconName: 'cat',
    },
  ],
  eventModes: [
    {
      label: 'En persona',
      iconName: 'team',
    },
    {
      label: 'En línea',
      iconName: 'vrheadset',
    },
    {
      label: 'Ambos',
      iconName: 'mix',
    },
  ],
  eventTypes: [
    {
      label: 'Sesión informativa',
      iconName: 'conversationChat',
    },
    { label: 'Conferencia', iconName: 'watch' },
    { label: 'Evento', iconName: 'vrspace' },
  ],
}

export default eventCategories
