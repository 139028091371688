import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { basicModuleWhiteList } from '@schemas/modules'

export default {
  schemaType: 'template',
  displayName: 'Basic Template',
  component: 'BasicTemplate',
  dataPacks: null,
  type: { label: 'Static', value: 'static' },

  content: [
    {
      title: 'Hero Section',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: ['HeroCompact', 'HeroCarousel', 'SimpleHero'],
      key: 'heroSection',
    },
    {
      title: 'Main Section',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: basicModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'BasicTemplate',
    heroSection: {
      component: 'Section',
      name: 'Hero Section',
      modules: [],
      sectionPosition: 1,
    },
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  // thumbnails: {
  //   '1x': `${baseUpload}/thumbnails/BasicTemplate-new`,
  //   '2x': `${baseUpload}/thumbnails/BasicTemplate@2x-new`,
  // },
}
