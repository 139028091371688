// import thumbnailsSitemap2x from '@griddo-images/thumbnails/Sitemap@2x.png';
// import thumbnailsSitemap from '@griddo-images/thumbnails/Sitemap.png';

export default {
  schemaType: 'template',
  displayName: 'Sitemap',
  component: 'Sitemap',
  dataPacks: null,
  type: { label: 'Static', value: 'static', special: 'sitemap' },
  singleInstance: true,

  content: [
    {
      title: 'Hero Section',
      key: 'heroSection',
      type: 'ComponentArray',
      maxItems: 1,
      whiteList: ['BasicContent'],
    },
    {
      title: 'Main Section',
      key: 'mainSection',
      type: 'ComponentArray',
      maxItems: 1,
      whiteList: ['BasicContent', 'MultimediaContent'],
    },
  ],

  configTabs: [],

  default: {
    type: 'template',
    templateType: 'Sitemap',
    heroSection: { modules: [] },
    mainSection: { modules: [] },
  },

  thumbnails: {
    // '1x': thumbnailsSitemap,
    // '2x': thumbnailsSitemap2x,
  },
}
