import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Grid Element',
  component: 'GridElement',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          mandatory: true,
          helptext: 'Recommended size: 296x296',
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          humanReadable: true,
          key: 'subtitle',
          hideable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },
  ],

  default: {
    component: 'GridElement',
    image: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: 'Subtitle',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
  },
}
