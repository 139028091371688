// import thumbnailsSpeaker from '@griddo-images/thumbnails/Speaker.png';
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Speaker',
  component: 'Speaker',
  dataPacks: null,
  category: 'collections',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          humanReadable: true,
        },
        {
          title: 'Imagen',
          type: 'ImageField',
          key: 'speakerImage',
          mandatory: true,
        },
        {
          title: 'URL vídeo',
          type: 'TextField',
          humanReadable: true,
          key: 'speakerVideoUrl',
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          humanReadable: true,
          key: 'speakerPosition',
          hideable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Link',
          type: 'UrlField',
          key: 'link',
          advanced: true,
        },
      ],
    },
  ],

  default: {
    component: 'Speaker',
    speakerImage: defaultBackgroundImage,
    speakerVideoUrl: '',
    title: { content: 'Nombre', tag: 'h2' },
    speakerPosition: 'Position',
    description: 'Lorem ipsum paragraph',
  },

  thumbnails: {
    // '1x': thumbnailsSpeaker,
    // '2x': thumbnailsSpeaker,
  },
}
