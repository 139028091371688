export default {
  es: {
    name: 'Spanish',
    label: 'ES',
    alias: ['es_ES'],
  },
  ca: {
    name: 'Catalan',
    label: 'CAT',
    alias: ['cat_ES'],
  },
  en: {
    name: 'English',
    label: 'EN',
    alias: ['en_GB'],
  },
  fr: {
    name: 'French',
    label: 'FR',
    alias: ['fr_FR'],
  },
}
