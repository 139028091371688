import thumbnailsThankYou from '@griddo-images/thumbnails/ThankYou.png'

import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import PrivacyForm from '@schemas/modules/PrivacyForm'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Thank You',
  component: 'ThankYou',
  dataPacks: ['BASIC_COMPONENTS'],
  type: {
    label: 'Basic Components',
    value: 'basic_components',
    mode: 'detail',
  },

  content: [
    {
      title: 'Icon',
      type: 'ImageField',
      key: 'icon',
      hideable: true,
    },
    {
      title: 'Title',
      type: 'HeadingField',
      key: 'legalTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      hideable: false,
      heltpText:
        'The variable {{name}} will be replaced by the name inserted by the user in the form',
      humanReadable: true,
    },
    {
      title: 'Subtitle',
      type: 'TextField',
      humanReadable: true,
      key: 'subtitle',
      hideable: true,
    },
    {
      title: 'Description',
      type: 'TextField',
      humanReadable: true,
      key: 'description',
      hideable: true,
    },
    {
      title: 'CTA',
      type: 'ComponentContainer',
      whiteList: ['Button'],
      key: 'button',
      hideable: true,
    },
    {
      title: 'Complement',
      type: 'ConditionalField',
      key: 'complement',
      mandatory: true,
      condition: 'image',
      options: [
        {
          name: 'image',
          value: 'image',
          title: 'Image',
        },
        {
          name: 'option',
          value: 'basicCard',
          title: 'Option',
        },
      ],
      fields: [
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          mandatory: true,
          condition: 'image',
        },
        {
          title: 'Cards',
          type: 'ComponentArray',
          elementUniqueSelection: false,
          mandatory: true,
          maxItems: 1,
          key: 'cards',
          whiteList: ['BasicCard'],
          condition: 'basicCard',
        },
      ],
    },
    {
      title: 'Decoration',
      type: 'ConditionalField',
      key: 'line',
      options: [
        {
          value: true,
          title: 'Line',
          name: 'yes',
        },
        {
          value: false,
          title: 'No Line',
          name: 'no',
        },
      ],
    },
    {
      title: 'Privacy Modal',
      type: 'ComponentArray',
      whiteList: ['PrivacyForm'],
      key: 'privacyForm',
      maxItems: 1,
      mandatory: true,
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ThankYou',
    icon: defaultBackgroundImage,
    legalTitle: { tag: 'h1', content: '¡Gracias {{name}}!' },
    subtitle: 'Tu solicitud se ha procesado correctamente',
    description: 'En breve tendrás noticias de nuestros asesores',
    button: {
      component: 'Button',
      buttonText: 'Enviar',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    complement: 'image',
    cards: [
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        detail: '',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'primary02',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
    image: {
      component: 'LinkableImage',
      file: { publicId: 'placeholders/griddo-background' },
    },
    line: true,
    privacyForm: {
      component: 'Section',
      name: 'Privacy Modal',
      modules: [{ ...PrivacyForm.default }],
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsThankYou,
    '2x': thumbnailsThankYou,
  },
}
