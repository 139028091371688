// import thumbnailsIntroForm2x from '@griddo-images/thumbnails/IntroForm@2x.png';
import Button from '@schemas/components/Button'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Intro Form',
  component: 'IntroForm',
  dataPacks: null,
  category: 'news_and_events',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: true,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'image',
              hideable: true,
              condition: false,
            },
            {
              title: 'Título',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
          ],
        },
        {
          title: 'Subtítulo',
          type: 'TextField',
          humanReadable: true,
          key: 'subtitle',
          hideable: true,
        },
        {
          title: 'Intro',
          type: 'TextField',
          humanReadable: true,
          key: 'entry',
          hideable: true,
        },
        {
          title: 'Detalle',
          type: 'RichText',
          humanReadable: true,
          key: 'content',
          hideable: true,
          html: true,
        },
        {
          title: 'Título de formulario',
          type: 'TextField',
          humanReadable: true,
          key: 'formTitle',
          hideable: true,
        },
        {
          title: 'Sign up button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: false,
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Anchor ID',
          type: 'TextField',
          humanReadable: true,
          key: 'anchorID',
          prefix: '#',
          hideable: true,
          helptext:
            'Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.',
        },
      ],
    },
  ],

  default: {
    component: 'IntroForm',
    title: {
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      tag: 'h2',
    },
    subtitle:
      'Donec lobortis sapien sit amet velit ullamcorper luctus. Fusce dictum nisl sit amet vulputate placerat. Sed faucibus lectus at felis dictum, sed rutrum eros pharetra. Nulla non dignissim risus, vel rutrum leo.',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur.',
    entry:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
    image: defaultBackgroundImage,
    formTitle: 'Are you in?',
    button: {
      ...Button.default,
      appearance: 'secondary',
      buttonText: 'Inscribirme',
    },
  },

  thumbnails: {
    // '1x': thumbnailsIntroForm2x,
    // '2x': thumbnailsIntroForm2x,
  },
}
