export const getCurrentDate = () => {
  const date = new Date()
  return `${date.getFullYear()}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getDate().toString().padStart(2, "0")}`;
}

const getCurrentDateYYYYMMDD_HHMMSS = () => {
  const date = new Date(),
    year = date.getFullYear(),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = date.getDate().toString().padStart(2, "0"),
    hour = date.getHours().toString().padStart(2, "0"),
    minutes = date.getMinutes().toString().padStart(2, "0"),
    seconds = date.getSeconds().toString().padStart(2, "0")

  return `${year}${month}${day} ${hour}${minutes}${seconds}`;
}

export  {
  getCurrentDateYYYYMMDD_HHMMSS
}