import * as React from 'react'
import {
  PageContext,
  PageProvider,
  SiteContext,
  SiteProvider as CoreSiteProvider,
} from '@griddo/core'

import './global-styles'
// import { defaults as cloudinaryDefaults } from '@constants/cloudinary'
import griddoDamDefaults from '@constants/dam'
import translations from '@translations'
// const griddoDamDefaults = {}

const AnimationContext = React.createContext(null)
// Added for legacy reasons
const ThemeContext = React.createContext(null)

function AnimationProvider(props) {
  const { children } = props
  return (
    <AnimationContext.Provider value={{ ...props }}>
      {children}
    </AnimationContext.Provider>
  )
}

function ThemeProvider(props) {
  const { children } = props
  return (
    <ThemeContext.Provider value={{ ...props }}>
      {children}
    </ThemeContext.Provider>
  )
}

// Proveedor que envuelve a la applicacion/web es un sitio ideal para añadir
// contextos como themeproviders, etc..
function SiteProvider(props) {
  const { theme, children, siteId } = props
  return (
    <CoreSiteProvider
      {...props}
      // cloudinaryDefaults={cloudinaryDefaults}
      griddoDamDefaults={griddoDamDefaults}
      translations={translations}
    >
      <div data-site-theme={theme.value || theme} style={{ height: '100%' }}>
        <React.Suspense fallback={null}>{children}</React.Suspense>
      </div>
    </CoreSiteProvider>
  )
}

export {
  PageContext,
  PageProvider,
  SiteContext,
  SiteProvider,
  AnimationContext,
  AnimationProvider,
  ThemeContext,
  ThemeProvider,
}
