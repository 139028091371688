import * as React from 'react'
import { Component as GriddoComponent } from '@griddo/core'

const Icon = React.lazy(() => import('./Icon' /* webpackChunkName: "component---src-Icon-js" */))
const Icons = React.lazy(() => import('./Icon' /* webpackChunkName: "component---src-Icons-js" */))

const AccordionElement = React.lazy(() =>
  import(
    './AccordionElement'
    /* webpackChunkName: "component---src-AccordionElement-js" */
    ),
)
const Text = React.lazy(() =>
  import('./Text' /* webpackChunkName: "component---src-Text-js" */),
)
const LinkableImage = React.lazy(() =>
  import(
    './LinkableImage'
    /* webpackChunkName: "component---src-LinkableImage-js" */
    ),
)
const TitleDecoration = React.lazy(() =>
  import(
    './TitleDecoration'
    /* webpackChunkName: "component---src-TitleDecoration-js" */
    ),
)
const BasicCard = React.lazy(() =>
  import(
    './BasicCard'
    /* webpackChunkName: "component---src-BasicCard-js" */
    ),
)
const FluidContainer = React.lazy(() =>
  import(
    './FluidContainer'
    /* webpackChunkName: "component---src-FluidContainer-js" */
    ),
)
const MaxWidth = React.lazy(() =>
  import('./MaxWidth' /* webpackChunkName: "component---src-MaxWidth-js" */),
)
const VideoComponent = React.lazy(() =>
  import(
    './VideoComponent' /* webpackChunkName: "component---src-VideoComponent-js" */
    ),
)
const Button = React.lazy(() =>
  import('./Button' /* webpackChunkName: "component---src-Button-js" */),
)
const ButtonModal = React.lazy(() =>
  import(
    './ButtonModal' /*webpackChunkName: "component---src-ButtonModal-js" */
    ),
)
const CypherCard = React.lazy(() =>
  import('./CypherCard' /*webpackChunkName: "component---src-CypherCard-js" */),
)
const ProgramAccordion = React.lazy(() =>
  import(
    './ProgramAccordion' /*webpackChunkName: "component---src-ProgramAccordion-js" */
    ),
)
const ProgramAccordionElement = React.lazy(() =>
  import(
    './ProgramAccordionElement' /* webpackChunkName: "component---src-ProgramAccordionElement-js" */
    ),
)
const ProgramTable = React.lazy(() =>
  import(
    './ProgramTable' /*webpackChunkName: "component---src-ProgramTable-js" */
    ),
)
const ProgramTableColumn = React.lazy(() =>
  import(
    './ProgramTableColumn' /*webpackChunkName: "component---src-ProgramTableColumn-js" */
    ),
)
const ProgramTableRow = React.lazy(() =>
  import(
    './ProgramTableRow' /*webpackChunkName: "component---src-ProgramTableRow-js" */
    ),
)
const SocialLinks = React.lazy(() =>
  import(
    './SocialLinks' /*webpackChunkName: "component---src-SocialLinks-js" */
    ),
)
const ProgramTabElement = React.lazy(() =>
  import(
    './ProgramTabElement' /*webpackChunkName: "component---src-ProgramTabElement-js" */
    ),
)
const DownloadElement = React.lazy(() =>
  import(
    './DownloadElement' /*webpackChunkName: "component---src-DownloadElement-js" */
    ),
)
const ProgramHeader = React.lazy(() =>
  import(
    './ProgramHeader' /*webpackChunkName: "component---src-ProgramHeader-js" */
    ),
)

const TestimonialCard = React.lazy(() =>
  import(
    './TestimonialCard' /*webpackChunkName: "component---src-TestimonialCard-js" */
    ),
)
const HorizontalTabElement = React.lazy(() =>
  import(
    './HorizontalTabElement' /*webpackChunkName: "component---src-HorizontalTabElement-js" */
    ),
)
const DropDown = React.lazy(() =>
  import(
    '@components/DropDown' /*webpackChunkName: "component---src-DropDown-js" */
    ),
)
const VerticalTabHeaderElement = React.lazy(() =>
  import(
    './VerticalTabElement/VerticalTabHeaderElement' /*webpackChunkName: "component---src-VerticalTabHeaderElement-js" */
    ),
)
const VerticalTabContentElement = React.lazy(() =>
  import(
    './VerticalTabElement/VerticalTabContentElement' /*webpackChunkName: "component---src-VerticalTabContentElement-js" */
    ),
)
const SecondaryColumnElement = React.lazy(() =>
  import(
    './SecondaryColumnElement' /*webpackChunkName: "component---src-SecondaryColumnElement-js" */
    ),
)
const MainColumnElement = React.lazy(() =>
  import(
    './MainColumnElement' /*webpackChunkName: "component---src-MainColumnElement-js" */
    ),
)
const ImageCard = React.lazy(() =>
  import('./ImageCard' /*webpackChunkName: "component---src-ImageCard-js" */),
)
const BasicHeroElement = React.lazy(() =>
  import(
    './BasicHeroElement' /*webpackChunkName: "component---src-BasicHeroElement-js" */
    ),
)
const HighlightedHeroElement = React.lazy(() =>
  import(
    './HighlightedHeroElement' /*webpackChunkName: "component---src-HighlightedHeroElement-js" */
    ),
)
const BackgroundHeroElement = React.lazy(() =>
  import(
    './BackgroundHeroElement' /*webpackChunkName: "component---src-BackgroundHeroElement-js" */
    ),
)
const VideoHeroElement = React.lazy(() =>
  import(
    './VideoHeroElement' /*webpackChunkName: "component---src-VideoHeroElement-js" */
    ),
)
const Carousel = React.lazy(() =>
  import('./Carousel' /*webpackChunkName: "component---src-Carousel-js" */),
)
const ProgramCard = React.lazy(() =>
  import(
    './ProgramCard' /*webpackChunkName: "component---src-ProgramCard-js" */
    ),
)
const LogoCard = React.lazy(() =>
  import('./LogoCard' /*webpackChunkName: "component---src-LogoCard-js" */),
)
const GridElement = React.lazy(() =>
  import(
    './GridElement' /*webpackChunkName: "component---src-GridElement-js" */
    ),
)
const InputComponent = React.lazy(() =>
  import(
    './InputComponent' /*webpackChunkName: "component---src-InputComponent-js" */
    ),
)
const SelectComponent = React.lazy(() =>
  import(
    './SelectComponent' /*webpackChunkName: "component---src-SelectComponent-js" */
    ),
)
const PhoneInputComponent = React.lazy(() =>
  import(
    './PhoneInputComponent' /*webpackChunkName: "component---src-PhoneInputComponent-js" */
    ),
)
const ErrorMessage = React.lazy(() =>
  import(
    './ErrorMessage' /*webpackChunkName: "component---src-ErrorMessage-js" */
    ),
)
const RadioButtonGroup = React.lazy(() =>
  import(
    './RadioButtonGroup' /*webpackChunkName: "component---src-RadioButtonGroup-js" */
    ),
)
const Form = React.lazy(() =>
  import('./Form' /*webpackChunkName: "component---src-Form-js" */),
)
const FormGroup = React.lazy(() =>
  import(
    './Form/FormGroup' /*webpackChunkName: "component---src-FormGroup-js" */
    ),
)
const FormGroupCol = React.lazy(() =>
  import(
    './Form/FormGroupCol' /*webpackChunkName: "component---src-FormGroupCol-js" */
    ),
)
const HeaderModalForms = React.lazy(() =>
  import(
    './HeaderModalForms' /*webpackChunkName: "component---src-HeaderModalForms-js" */
    ),
)
const ContainerModalForms = React.lazy(() =>
  import(
    './ContainerModalForms' /*webpackChunkName: "component---src-ContainerModalForms-js" */
    ),
)
const InputSwitch = React.lazy(() =>
  import(
    '@components/Form/InputSwitch' /*webpackChunkName: "component---src-InputSwitch-js" */
    ),
)
const ProgramListCard = React.lazy(() =>
  import(
    './ProgramListCard' /*webpackChunkName: "component---src-ProgramListCard-js" */
    ),
)
const NewsCard = React.lazy(() =>
  import('./NewsCard' /*webpackChunkName: "component---src-NewsCard-js" */),
)
const StaffCard = React.lazy(() =>
  import('./StaffCard' /*webpackChunkName: "component---src-StaffCard-js" */),
)
const HeroStaff = React.lazy(() =>
  import('./HeroStaff' /*webpackChunkName: "component---src-HeroStaff-js" */),
)
const HeroEvent = React.lazy(() =>
  import('./HeroEvent' /*webpackChunkName: "component---src-HeroEvent-js" */),
)
const HeroDetail = React.lazy(() =>
  import(
    '@components/HeroDetail' /*webpackChunkName: "component---src-HeroDetail-js" */
    ),
)
const NoResultText = React.lazy(() =>
  import(
    '@components/NoResultText' /*webpackChunkName: "component---src-NoResultText-js" */
    ),
)
const Speaker = React.lazy(() =>
  import('./Speaker' /*webpackChunkName: "component---src-Speaker-js" */),
)
const EventCard = React.lazy(() =>
  import('./EventCard' /*webpackChunkName: "component---src-EventCard-js" */),
)
const InteractiveCard = React.lazy(() =>
  import(
    './InteractiveCard' /*webpackChunkName: "component---src-InteractiveCard-js" */
    ),
)
const FormLegalText = React.lazy(() =>
  import(
    './FormLegalText' /*webpackChunkName: "component---src-FormLegalText-js" */
    ),
)
const BodyDetail = React.lazy(() =>
  import('./BodyDetail' /*webpackChunkName: "component---src-BodyDetail-js" */),
)
const Group = React.lazy(() =>
  import('./Group' /*webpackChunkName: "component---src-Group-js" */),
)

const ProgramUsp = React.lazy(() =>
  import('./ProgramUsp' /*webpackChunkName: "component---src-ProgramUsp-js" */),
)
const ProgramTag = React.lazy(() =>
  import('./ProgramTag' /*webpackChunkName: "component---src-ProgramTag-js" */),
)
const ProgramQuickFact = React.lazy(() =>
  import(
    './ProgramQuickFact' /*webpackChunkName: "component---src-ProgramQuickFact-js" */
    ),
)
const VideoModal = React.lazy(() =>
  import('./VideoModal' /*webpackChunkName: "component---src-VideoModal-js" */),
)
const Loading = React.lazy(() =>
  import('./Loading' /*webpackChunkName: "component---src-Loading-js" */),
)

const BreadCrumb = React.lazy(() =>
  import(
    '@components/BreadCrumb' /*webpackChunkName: "component---src-BreadCrumb-js" */
    ),
)

const BackgroundImageTrans = React.lazy(() =>
  import(
    '@components/BackgroundImageTrans' /*webpackChunkName: "component---src-BackgroundImageTrans-js" */
    ),
)

const ReportList = React.lazy(() =>
  import(
    '@components/ReportList' /*webpackChunkName: "component---src-ReportList-js" */
    ),
)

const NewsList = React.lazy(() =>
  import(
    '@components/NewsList' /*webpackChunkName: "component---src-NewsList-js" */
    ),
)

const StaffList = React.lazy(() =>
  import(
    '@components/StaffList' /*webpackChunkName: "component---src-StaffList-js" */
    ),
)

const EventList = React.lazy(() =>
  import(
    '@components/EventList' /*webpackChunkName: "component---src-EventList-js" */
    ),
)

const FilterSelect = React.lazy(() =>
  import(
    '@components/FilterSelect' /*webpackChunkName: "component---src-FilterSelect-js" */
    ),
)

const ProgramList = React.lazy(() =>
  import(
    '@components/ProgramList' /*webpackChunkName: "component---src-ProgramList-js" */
    ),
)

const LandingQuickFact = React.lazy(() =>
  import(
    '@components/LandingQuickFact' /*webpackChunkName: "component---src-LandingQuickFact-js" */
    ),
)

const LandingProgramCard = React.lazy(() =>
  import(
    '@components/LandingProgramCard' /*webpackChunkName: "component---src-LandingProgramCard-js" */
    ),
)

const Pagination = React.lazy(() =>
  import(
    '@components/Pagination' /*webpackChunkName: "component---src-Pagination-js" */
    ),
)

const Image = React.lazy(() =>
  import(
    '@components/Image' /*webpackChunkName: "component---src-Image-js" */
    ),
)

const ImageTrans = React.lazy(() =>
  import(
    '@components/ImageTrans' /*webpackChunkName: "component---src-ImageTrans-js" */
    ),
)

const ListLoadMoreButton = React.lazy(() =>
  import(
    '@components/ListLoadMoreButton' /*webpackChunkName: "component---src-ListLoadMoreButton-js" */
    ),
)

const DynamicIcon = React.lazy(() =>
  import(
    '@components/DynamicIcon' /*webpackChunkName: "component---src-DynamicIcon-js" */
    ),
)

const SimpleText = React.lazy(() =>
  import(
    '@components/SimpleText' /*webpackChunkName: "component---src-SimpleText-js" */
    ),
)

const GriddoImage = React.lazy(() =>
  import(
    '@components/GriddoImage' /*webpackChunkName: "component---src-GriddoImage-js" */
    ),
)

const HeroList = React.lazy(() =>
  import(
    '@components/HeroList' /*webpackChunkName: "component---src-HeroList-js" */
    ),
)

const components = {
  Icon,
  Icons,
  AccordionElement,
  Text,
  LinkableImage,
  TitleDecoration,
  BasicCard,
  FluidContainer,
  MaxWidth,
  Button,
  ButtonModal,
  CypherCard,
  VideoComponent,
  ProgramAccordion,
  ProgramAccordionElement,
  ProgramTable,
  ProgramTableColumn,
  ProgramTableRow,
  SocialLinks,
  ProgramTabElement,
  DownloadElement,
  ProgramUsp,
  ProgramHeader,
  ProgramTag,
  TestimonialCard,
  HorizontalTabElement,
  VerticalTabHeaderElement,
  VerticalTabContentElement,
  DropDown,
  ProgramQuickFact,
  SecondaryColumnElement,
  MainColumnElement,
  ImageCard,
  BasicHeroElement,
  HighlightedHeroElement,
  BackgroundHeroElement,
  VideoHeroElement,
  Carousel,
  ProgramCard,
  LogoCard,
  GridElement,
  InputComponent,
  SelectComponent,
  PhoneInputComponent,
  ErrorMessage,
  RadioButtonGroup,
  Form,
  FormGroup,
  FormGroupCol,
  HeaderModalForms,
  ContainerModalForms,
  InputSwitch,
  ProgramListCard,
  NewsCard,
  HeroStaff,
  StaffCard,
  HeroDetail,
  HeroEvent,
  NoResultText,
  Speaker,
  EventCard,
  InteractiveCard,
  FormLegalText,
  BodyDetail,
  Group,
  VideoModal,
  Loading,
  BreadCrumb,
  BackgroundImageTrans,
  ReportList,
  NewsList,
  StaffList,
  FilterSelect,
  EventList,
  ProgramList,
  LandingQuickFact,
  LandingProgramCard,
  Pagination,
  Image,
  ImageTrans,
  ListLoadMoreButton,
  DynamicIcon,
  SimpleText,
  GriddoImage,
  HeroList,
}

export {
  Icon,
  Icons,
  AccordionElement,
  Text,
  LinkableImage,
  TitleDecoration,
  BasicCard,
  FluidContainer,
  MaxWidth,
  Button,
  ButtonModal,
  CypherCard,
  VideoComponent,
  ProgramAccordion,
  ProgramAccordionElement,
  ProgramTable,
  ProgramTableColumn,
  ProgramTableRow,
  SocialLinks,
  ProgramTabElement,
  DownloadElement,
  ProgramUsp,
  ProgramHeader,
  ProgramTag,
  TestimonialCard,
  HorizontalTabElement,
  VerticalTabHeaderElement,
  VerticalTabContentElement,
  DropDown,
  ProgramQuickFact,
  SecondaryColumnElement,
  MainColumnElement,
  ImageCard,
  BasicHeroElement,
  HighlightedHeroElement,
  BackgroundHeroElement,
  VideoHeroElement,
  Carousel,
  ProgramCard,
  LogoCard,
  GridElement,
  InputComponent,
  SelectComponent,
  PhoneInputComponent,
  ErrorMessage,
  RadioButtonGroup,
  Form,
  FormGroup,
  FormGroupCol,
  HeaderModalForms,
  ContainerModalForms,
  InputSwitch,
  ProgramListCard,
  NewsCard,
  HeroStaff,
  StaffCard,
  HeroDetail,
  HeroEvent,
  NoResultText,
  Speaker,
  EventCard,
  InteractiveCard,
  FormLegalText,
  BodyDetail,
  Group,
  VideoModal,
  Loading,
  BreadCrumb,
  BackgroundImageTrans,
  ReportList,
  NewsList,
  StaffList,
  FilterSelect,
  EventList,
  ProgramList,
  LandingQuickFact,
  LandingProgramCard,
  Pagination,
  Image,
  ImageTrans,
  ListLoadMoreButton,
  DynamicIcon,
  SimpleText,
  GriddoImage,
  HeroList,
}

function Component(props) {
  return (
    <React.Suspense fallback={null}>
      <GriddoComponent libComponents={components} {...props} />
    </React.Suspense>
  )
}

export default components

export { Component }
