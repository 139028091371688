import thumbnail from '@griddo-images/thumbnails/Header.png'
import thumbnail2x from '@griddo-images/thumbnails/Header@2x.png'

export default {
  schemaType: 'module',
  displayName: 'MainNavigation',
  component: 'Header',
  type: 'header',
  category: 'navigation',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
          mandatory: true,
        },
        {
          title: 'PhoneNumber',
          key: 'phoneNumber',
          type: 'TextField',
          humanReadable: true,
          hideable: true,
        },
        {
          title: 'SearchBox',
          type: 'RadioGroup',
          key: 'searchBox',
          helptext: '',
          options: [
            {
              title: 'Yes',
              name: 'yes',
              value: 'yes',
            },
            {
              title: 'No',
              name: 'no',
              value: 'no',
            },
          ],
          hideable: false,
        },
        {
          title: 'CampusUrl',
          type: 'UrlField',
          key: 'campusUrl',
          advanced: true,
          hideable: true,
        },
        {
          title: 'Search Result',
          type: 'ComponentContainer',
          whiteList: ['Link'],
          key: 'button',
          hideable: false,
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          type: 'UniqueCheck',
          key: 'setAsDefault',
          options: [{ title: 'Set as Header default' }],
        },
        {
          title: 'Select Main Menu',
          key: 'mainMenu',
          type: 'AsyncSelect',
          entity: 'menu_containers',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'Header',
    type: 'header',
    title: 'Main Navigation',
    phoneNumber: '+376 878 300',
    setAsDefault: true,
    mainMenu: null,
    button: {
      component: 'Link',
      text: 'Link to results page',
      style: 'primary',
      isLink: 'link',
      elements: [],
      url: {
        url: '',
        linkToURL: null,
        newTab: true,
        noFollow: false,
        size: null,
        icon: null,
        linkContainer: null,
      },
    },
  },

  thumbnails: {
    '1x': thumbnail,
    '2x': thumbnail2x,
  },
}
