import thumbnailsEventForm from '@griddo-images/thumbnails/EventForm.png'
import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'primary01'
defaultBtn.buttonText = 'Solicita información'

export default {
  schemaType: 'module',
  displayName: 'Event Form',
  component: 'EventForm',
  dataPacks: null,
  category: 'forms',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          humanReadable: true,
          key: 'subtitle',
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'TextField',
          humanReadable: true,
          key: 'description',
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Descargar',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: false,
        },
      ],
    },
  ],

  default: {
    component: 'DownloadForm',
    title: '¿Te lo vas a perder?',
    subtitle: 'Déjanos tus datos',
    description: 'Disfruta del evento',
    buttonText: 'Inscribirme',
    button: { ...defaultBtn },
  },

  thumbnails: {
    '1x': thumbnailsEventForm,
    '2x': thumbnailsEventForm,
  },
}
