import { defaultBackgroundImage } from '@constants/dam'
import { breakpoints } from '@constants/theme'

export default {
  schemaType: 'component',
  displayName: 'VideoComponent',
  component: 'VideoComponent',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'URL Video',
          type: 'TextField',
          humanReadable: true,
          key: 'url',
          hideable: false,
        },
        {
          title: 'Alt',
          type: 'TextField',
          humanReadable: true,
          key: 'altText',
          hideable: true,
        },
        {
          title: 'Thumbnail',
          type: 'ComponentContainer',
          whiteList: ['LinkableImage'],
          key: 'thumbnail',
          mandatory: true,
          helptext: 'Recommended size: 1920x1080',
        },
      ],
    },
  ],

  default: {
    component: 'VideoComponent',
    url: '',
    thumbnail: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
    },
    responsive: [
      {
        breakpoint: breakpoints.default,
        width: '400px',
      },
      {
        breakpointName: 's',
        breakpoint: breakpoints.s,
        width: '600px',
      },
      {
        breakpointName: 'm',
        breakpoint: breakpoints.m,
        width: '900px',
      },
      {
        breakpointName: 'l',
        breakpoint: breakpoints.l,
        width: '1100px',
      },
      {
        breakpointName: 'xl',
        breakpoint: breakpoints.l,
        width: '1300px',
      },
    ],
  },
}
