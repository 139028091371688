export default {
  schemaType: 'component',
  displayName: 'Download Element',
  component: 'DownloadElement',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',

          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          humanReadable: true,
        },
        {
          title: 'File',
          type: 'FileField',
          key: 'file',
        },
      ],
    },
  ],

  default: {
    component: 'DownloadElement',
    title: { content: 'Lorem ipsum', tag: 'h2' },
    file: '',
  },
}
