export default {
  schemaType: 'component',
  displayName: 'Landing Program Card',
  component: 'LandingProgramCard',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          madatory: true,
        },
        {
          type: 'SliderField',
          step: 1,
          minvalue: 0,
          maxvalue: 100,
          key: 'positionX',
          title: 'Horizontal shift',
        },
        {
          type: 'SliderField',
          step: 1,
          minvalue: 0,
          maxvalue: 100,
          key: 'positionY',
          title: 'Vertical shift',
        },
        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'PROGRAM_LANDING' }],
          key: 'data',
          mandatory: true,
          fullRelations: true,
          quantity: 1,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Start',
          type: 'TextField',
          humanReadable: true,
          key: 'start',
          mandatory: true,
        },
        {
          title: 'Duration',
          type: 'TextField',
          humanReadable: true,
          key: 'duration',
          mandatory: true,
        },
        {
          title: 'Label button',
          type: 'TextField',
          humanReadable: true,
          key: 'btnLabel',
          mandatory: true,
        },
        {
          title: 'Mode',
          type: 'RadioGroup',
          key: 'mode',
          options: [
            {
              title: 'Online',
              name: 'Online',
              value: 'Online',
            },
            {
              title: 'Presencial',
              name: 'Presencial',
              value: 'Presencial',
            },
            {
              title: 'Mixto',
              name: 'Mixto',
              value: 'Mixto',
            },
          ],
          mandatory: true,
        },
        {
          title: 'Language',
          type: 'RadioGroup',
          key: 'language',
          options: [
            {
              title: 'Castellano',
              name: 'Castellano',
              value: 'Castellano',
            },
            {
              title: 'Francés',
              name: 'Francés',
              value: 'Francés',
            },
            {
              title: 'Inglés',
              name: 'Inglés',
              value: 'Inglés',
            },
            {
              title: 'Catalán',
              name: 'Catalán',
              value: 'Catalán',
            },
          ],
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'LandingProgramCard',
    image: {
      alt: '',
      publicId: 'placeholders/griddo-background',
    },
    title: { content: 'Title', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    start: 'Start date',
    duration: 'Duration',
    mode: 'Online',
    language: 'Castellano',
    btnLabel: '¡Inscríbete ya!',
    hasDistributorData: true,
    positionX: 50,
    positionY: 50,
    data: {
      mode: 'manual',
      sources: [{ structuredData: 'PROGRAM_LANDING' }],
      order: 'recent',
      quantity: 1,
      fullRelations: true,
    },
  },
}
