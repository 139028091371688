// import thumbnailsLogoCard2x from '@griddo-images/thumbnails/LogoCard@2x.png';
// import thumbnailsLogoCard from '@griddo-images/thumbnails/LogoCard.png';
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Logo Card',
  component: 'LogoCard',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Description',
          type: 'TextField',
          humanReadable: true,
          key: 'description',
          hideable: true,
        },
        {
          title: 'Url',
          type: 'UrlField',
          key: 'url',
          hideable: true,
        },
      ],
    },
  ],

  default: {
    component: 'LogoCard',
    title: {
      content: 'Lorem ipsum',
      tag: 'h2',
    },
    image: defaultBackgroundImage,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    url: {
      url: '',
      linkToURL: null,
      newTab: true,
      noFollow: false,
      size: null,
      icon: null,
      linkContainer: null,
    },
  },

  thumbnails: {
    // '1x': thumbnailsLogoCard,
    // '2x': thumbnailsLogoCard2x,
  },
}
