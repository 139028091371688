import thumbnailsSpeakersCollection from '@griddo-images/thumbnails/SpeakersCollection.png'
import thumbnailsSpeakersCollection2x from '@griddo-images/thumbnails/SpeakersCollection@2x.png'
import layoutsSpeakersCollectionlayout002 from '@griddo-images/layouts/SpeakersCollection/layout002.png'
import layoutsSpeakersCollectionlayout001 from '@griddo-images/layouts/SpeakersCollection/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'

import Speaker from '@schemas/components/Speaker'

export default {
  schemaType: 'module',
  displayName: 'Speakers Collection',
  component: 'SpeakersCollection',
  dataPacks: null,
  category: 'people',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'iconImage',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Speakers',
          type: 'ComponentArray',
          whiteList: ['Speaker'],
          elementUniqueSelection: true,
          key: 'elements',
          maxItems: null,
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsSpeakersCollectionlayout001,
            },
            {
              value: 'L002',
              img: layoutsSpeakersCollectionlayout002,
            },
          ],
          columns: 2,
        },
      ],
    },
  ],

  default: {
    component: 'SpeakersCollection',
    iconImage: defaultBackgroundImage,
    kind: 'Speaker',
    elements: [
      { ...Speaker.default },
      { ...Speaker.default },
      { ...Speaker.default },
    ],
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    layout: 'L001',
  },

  thumbnails: {
    '1x': thumbnailsSpeakersCollection,
    '2x': thumbnailsSpeakersCollection2x,
  },
}
