const griddoDamDefaults = {
  quality: 65,
  crop: 'cover',
  loading: 'lazy',
  decoding: 'auto',
  formats: ['avif', 'webp'],
}

export const defaultBackgroundImage = {
  alt: '',
  name: 'griddo-background.jpeg',
  url: 'https://images.pre.griddo.universitatcarlemany.com/griddo-background_2',
  thumb:
    'https://images.pre.griddo.universitatcarlemany.com/w/192/h/144/griddo-background_2',
  damId: 'griddo-background_2',
  published: '2022-10-03T09:28:47.969Z',
  size: 395795,
  width: 2370,
  height: 1580,
  orientation: 'L',
}

export default griddoDamDefaults
