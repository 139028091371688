import thumbnailsReportDetail from '@griddo-images/thumbnails/ReportDetail.png'

import { getCurrentDate } from '@utils/CurrentDate'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Report Detail',
  component: 'ReportDetail',
  dataPacks: ['REPORTS'],
  type: { label: 'Reports', value: 'reports', mode: 'detail' },

  content: [
    {
      title: 'Report Title',
      type: 'HeadingField',
      key: 'reportTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      humanReadable: true,
    },
    {
      title: 'Image',
      type: 'ImageField',
      key: 'image',
      mandatory: true,
      helptext: 'Recommended size: 760x430',
    },
    {
      title: 'Summary',
      type: 'RichText',
      humanReadable: true,
      key: 'summary',
      html: true,
    },
    {
      title: 'Author',
      type: 'TextField',
      humanReadable: true,
      key: 'author',
    },
    {
      title: 'Publication Date',
      type: 'DateField',
      key: 'publishedDate',
      indexable: true,
    },
    {
      title: 'News Hour',
      type: 'TextField',
      humanReadable: true,
      key: 'hour',
    },
    {
      title: 'Report Topic',
      type: 'AsyncCheckGroup',
      source: 'REPORT_CATEGORIES',
      key: 'category',
      mandatory: true,
    },
    {
      title: 'Report Entry',
      type: 'TextField',
      humanReadable: true,
      key: 'entry',
    },
    {
      title: 'Report Body',
      type: 'Wysiwyg',
      humanReadable: true,
      key: 'body',
      html: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: ['SocialShare', 'DownloadCollection', 'ReportsDistributor'],
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ReportDetail',
    reportTitle: { content: 'Title', tag: 'h1' },
    image: defaultBackgroundImage,
    summary: 'Lorem ipsum',
    author: 'Name Surname',
    publishedDate: getCurrentDate(),
    hour: '00:00',
    category: [],
    entry: 'Lorem ipsum paragraph',
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [
        {
          component: 'DownloadCollection',
          nesting: true,
          icon: defaultBackgroundImage,
          title: {
            content: 'Curabitur diam nulla',
            tag: 'h2',
          },
          subtitle: {
            content: 'Curabitur diam nulla',
            tag: 'h2',
          },
          detail:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur.</p>\n',
          theme: 'ultraLightYellow',
          kind: 'DownloadElement',
          elements: [
            {
              component: 'DownloadElement',
              title: { content: 'Lorem ipsum', tag: 'h2' },
              file: '',
            },
            {
              component: 'DownloadElement',
              title: { content: 'Lorem ipsum', tag: 'h2' },
              file: '',
            },
          ],
        },
      ],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsReportDetail,
    '2x': thumbnailsReportDetail,
  },
}
