import thumbnailsDownloadCollection from '@griddo-images/thumbnails/DownloadCollection.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Download Collection',
  component: 'DownloadCollection',
  category: 'collections',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Download Element',
          type: 'ComponentArray',
          whiteList: ['DownloadElement'],
          elementUniqueSelection: true,
          key: 'elements',
          maxItems: null,
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Anchor ID',
          type: 'TextField',
          humanReadable: true,
          key: 'anchorID',
          prefix: '#',
          hideable: true,
          helptext:
            'Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.',
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'DownloadCollection',
    nesting: false,
    icon: defaultBackgroundImage,
    title: {
      content: 'Curabitur diam nulla',
      tag: 'h2',
    },
    subtitle: {
      content: 'Curabitur diam nulla',
      tag: 'h2',
    },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
    theme: 'light',
    kind: 'DownloadElement',
    elements: [
      {
        component: 'DownloadElement',
        title: { content: 'Lorem ipsum', tag: 'h2' },
        file: '',
      },
      {
        component: 'DownloadElement',
        title: { content: 'Lorem ipsum', tag: 'h2' },
        file: '',
      },
    ],
  },

  thumbnails: {
    '1x': thumbnailsDownloadCollection,
    '2x': thumbnailsDownloadCollection,
  },
}
