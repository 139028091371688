import { getCampaignCode } from '@utils/GetUrlParameters'
import { getWindow } from 'ssr-window'

const window = getWindow()

const groups = ['google', 'yahoo', 'msnlive', 'ask', 'bing', 'babylon']

const getCookie = (cookie) => {
  const cookies = document?.cookie?.split(';')

  if (cookies?.length > 0) {
    const cookieValue = cookies
      ?.map((c) => {
        const cookieSplit = c.split(/=(.*)/s)
        if (cookie === cookieSplit[0].trim()) return cookieSplit[1]
      })
      .filter((e) => e)

    if (cookieValue?.length > 0) return cookieValue[0]
  }

  return ''
}

const setCookie = (name, value, exp) => {
  const d = new Date()
  d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000)
  let expires = 'expires=' + d.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

const getUtmCampaign = (url) => {
  const campaignCode = getCampaignCode(url)
  if (campaignCode) return campaignCode

  const utm_campaign = getCookie('utm_campaign')
  const utmCampaign = JSON.parse(utm_campaign)
  return window.atob(utmCampaign.utm_campaign) || ''
}

const createCookiesCampaign = async (
  campaignCode = 'I90700M0001',
  isAds = false
) => {
  const TRACKING_DATA = [
    {
      domain: window.location.hostname,
      referer_url: '',
      referer_domain: '',
      group: 'Direct',
      campaign: campaignCode,
    },
  ]

  if (document.referrer) {
    const refererDomain = document.referrer.split('://')
    const domain = refererDomain[1].split('.')

    TRACKING_DATA.referer_url = document.referrer
    TRACKING_DATA.referer_domain = refererDomain[1]?.replaceAll('/', '') || ''

    let domainGroup = domain[0] === 'www' ? domain[1] : domain[0]
    const indexGroup = groups.indexOf(domainGroup)

    if (
      indexGroup !== -1 &&
      groups[indexGroup] === domainGroup &&
      campaignCode === 'I90700M0001'
    ) {
      // SEO Traffic
      if (!isAds) campaignCode = 'I90701M0001'

      // SEO Traffic or ADS
      TRACKING_DATA.group = groups[indexGroup]
      TRACKING_DATA.campaignCode = campaignCode
    }
  }

  const utm_campaign = {
    utm_campaign: window.btoa(encodeURIComponent(campaignCode)),
  }

  setCookie('TRACKING_DATA', window.btoa(JSON.stringify(TRACKING_DATA)), 100)
  setCookie('utm_campaign', JSON.stringify(utm_campaign), 100)
}

const setCampaign = async () => {
  if (!window || typeof window === 'undefined') return

  // ADS Traffic
  const params = new URLSearchParams(window.location.search)
  const campaignCodeParam = params.get('c')

  if (campaignCodeParam && campaignCodeParam !== '') {
    await createCookiesCampaign(campaignCodeParam, true)
  } else {
    // SEO or Direct traffic
    const campaignCode = getCampaignCode(window.location.href)
    const utm_campaign = getCookie('utm_campaign')

    if (!utm_campaign) {
      await createCookiesCampaign(campaignCode, false)
    }
  }
}

export { setCampaign, getCookie, getUtmCampaign, setCookie }
