import thumbnailsReportsDistributor from '@griddo-images/thumbnails/ReportsDistributor.png'
import { defaultBackgroundImage } from '@constants/dam'

import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'link'
defaultBtn.buttonText = 'Ver más'

export default {
  schemaType: 'module',
  displayName: 'Reports Distributor',
  component: 'ReportsDistributor',
  category: 'news_and_events',
  dataPacks: ['REPORTS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: true,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
            {
              title: 'Entry',
              type: 'TextField',
              key: 'entry',
              hideable: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'REPORTS' }],
          key: 'data',
          mandatory: true,
        },
        {
          title: 'Grouping Link',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Distributor type',
          key: 'distributorType',
          type: 'ConditionalField',
          options: [
            {
              name: 'expandable',
              value: 'expandable',
              title: 'Expandable',
            },
            {
              name: 'fully',
              value: 'fully',
              title: 'Fully Visible Distributor',
            },
          ],
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'ReportsDistributor',
    nesting: false,
    icon: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    entry:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structureData:'REPORTS' }],
      order: 'recent',
      quantity: 6,
    },
    line: true,
    theme: 'light',
    button: { ...defaultBtn },
    distributorType: 'expandable',
  },

  thumbnails: {
    '1x': thumbnailsReportsDistributor,
    '2x': thumbnailsReportsDistributor,
  },
}
