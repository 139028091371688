import thumbnailsProgramDetail from '@griddo-images/thumbnails/ProgramDetail.png'
import ProgramMoreInfo from '@schemas/components/ProgramMoreInfo'
import ButtonRequestAdmission from '@schemas/components/ButtonRequestAdmission'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Program Detail',
  component: 'ProgramDetail',
  dataPacks: ['PROGRAMS'],
  type: { label: 'Programs', value: 'programs', mode: 'detail' },
  content: [
    {
      title: 'ID',
      type: 'TextField',
      humanReadable: true,
      key: 'identifier',
      mandatory: true,
    },
    {
      title: 'ID Dedication',
      type: 'TextField',
      humanReadable: true,
      key: 'idDedication',
      mandatory: true,
    },
    {
      title: 'ID Campus',
      type: 'TextField',
      humanReadable: true,
      key: 'idCampus',
      mandatory: true,
    },
    {
      title: 'Program Title',
      type: 'HeadingField',
      key: 'programTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      helptext: 'Maximum 80 characters',
      humanReadable: true,
    },
    {
      title: 'Use image as background',
      type: 'ConditionalField',
      key: 'conditionalBackground',
      mandatory: true,
      options: [
        {
          value: true,
          title: 'Yes',
          name: 'yes',
        },
        {
          value: false,
          title: 'No',
          name: 'no',
        },
      ],
      fields: [
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'backgroundImage',
          condition: true,
          helptext: 'Recommended size: 1920x1080',
        },
      ],
    },
    {
      title: 'USP',
      type: 'ComponentContainer',
      whiteList: ['ProgramUsp'],
      key: 'programUsp',
      hideable: true,
    },
    {
      title: 'Detail',
      type: 'TextField',
      humanReadable: true,
      key: 'detail',
      mandatory: true,
      helptext: 'Maximum 220 characters',
    },
    {
      title: 'Main Tag',
      type: 'TextField',
      humanReadable: true,
      key: 'mainTag',
      hideable: true,
    },
    {
      title: 'Additional Tags',
      type: 'ComponentArray',
      whiteList: ['ProgramTag'],
      key: 'programTags',
      maxItems: 3,
      hideable: true,
      helptext: 'Maximum 3 tags',
    },
    {
      title: 'Hero Image',
      type: 'ImageField',
      key: 'image',
      mandatory: true,
      helptext: 'Recommended image size: 357x418',
    },
    {
      title: 'Distributor Image',
      type: 'ImageField',
      key: 'distributorImage',
      mandatory: true,
    },
    {
      title: 'Programs Type',
      type: 'AsyncCheckGroup',
      source: 'PROGRAM_TYPES',
      key: 'programType',
      mandatory: true,
    },
    {
      title: 'StaffGroup',
      type: 'ReferenceField',
      key: 'data',
      sources: [{ structuredData: 'STAFF' } ],
      mandatory: true,
      selectionType: ["manual"],
    },
    {
      title: 'Area',
      type: 'AsyncCheckGroup',
      source: 'AREA',
      key: 'area',
      mandatory: true,
    },
    {
      title: 'ODS',
      type: 'AsyncCheckGroup',
      source: 'ODS',
      key: 'ods',
      mandatory: true,
    },
    {
      title: 'Start',
      type: 'TextField',
      humanReadable: true,
      key: 'start',
      mandatory: true,
    },
    {
      title: 'Duration',
      type: 'TextField',
      humanReadable: true,
      key: 'duration',
      mandatory: true,
    },
    {
      title: 'End',
      type: 'TextField',
      key: 'end',
      mandatory: true,
    },
    {
      title: 'Mode',
      type: 'RadioGroup',
      key: 'mode',
      options: [
        {
          title: 'Online',
          name: 'Online',
          value: 'Online',
        },
        {
          title: 'Presencial',
          name: 'Presencial',
          value: 'Presencial',
        },
        {
          title: 'Mixto',
          name: 'Mixto',
          value: 'Mixto',
        },
      ],
      mandatory: true,
    },
    {
      title: 'Language',
      type: 'RadioGroup',
      key: 'language',
      options: [
        {
          title: 'Castellano',
          name: 'Castellano',
          value: 'Castellano',
        },
        {
          title: 'Francés',
          name: 'Francés',
          value: 'Francés',
        },
        {
          title: 'Inglés',
          name: 'Inglés',
          value: 'Inglés',
        },
        {
          title: 'Catalán',
          name: 'Catalán',
          value: 'Catalán',
        },
      ],
      mandatory: true,
    },
    {
      title: 'Show Price field',
      type: 'ConditionalField',
      key: 'conditionalPrice',
      mandatory: true,
      options: [
        {
          value: true,
          title: 'Yes',
          name: 'yes',
        },
        {
          value: false,
          title: 'No',
          name: 'no',
        },
      ],
    },
    {
      title: 'Price',
      type: 'TextField',
      humanReadable: true,
      key: 'price',
      mandatory: true,
    },
    {
      title: 'Additional Quick Fackts',
      type: 'ComponentArray',
      whiteList: ['ProgramQuickFact'],
      key: 'programQuickFact',
      maxItems: 4,
      hideable: true,
      helptext: 'Maximum 4 items',
    },
    {
      title: 'Program Tabs',
      type: 'ComponentArray',
      whiteList: ['ProgramTabs'],
      key: 'programTabs',
      maxItems: 1,
      mandatory: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: [
        'AccordionCollection',
        'BasicContent',
        'CardCollection',
        'CypherCollection',
        'DownloadCollection',
        'MultimediaContent',
        'ColumnText',
        'BasicText',
        'Spacer',
        'StudyPlan',
        'Testimonials',
        'HighlightedContent',
        'FeaturedBlock',
        'FeaturedAudiovisual',
        'GoToTop',
        'HorizontalTabs',
        'CarouselCollection',
        'VerticalTabs',
        'MixedCollection',
        'ColumnsPresentation',
        'ProgramsDistributor',
        'Grid',
        'LogoCollection',
        'SocialShare',
        'StaffDistributor',
        'NewsDistributor',
        'FAQCollection',
      ],
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['ProgramMoreInfo', 'ButtonRequestAdmission'],
      key: 'moreInfo',
      maxItems: 2,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ProgramDetail',
    idDedication: 1,
    idCampus: 1,
    programTitle: { content: 'Title', tag: 'h2' },
    conditionalBackground: false,
    hasDistributorData: true,
    data: {
      mode: 'manual',
      order: 'alpha',
      sources: [{ structuredData:'STAFF' } ],
    },
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 90,
      color: 'white',
    },
    programType: [],
    area: [],
    ods: [],
    programUsp: {
      component: 'ProgramUsp',
      icon: defaultBackgroundImage,
      usp: 'Titulación Oficial Europea',
    },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
    mainTag: 'Últimas plazas',
    programTags: {
      component: 'Section',
      name: 'Program Tag',
      modules: [],
    },
    image: defaultBackgroundImage,
    start: 'Start date',
    end: 'End date',
    duration: 'Duration',
    mode: 'Online',
    language: 'Castellano',
    conditionalPrice: false,
    price: '0.0',
    programQuickFact: {
      component: 'Section',
      name: 'Program QuickFact',
      modules: [],
    },
    programTabs: {
      component: 'Section',
      name: 'Program Tabs',
      modules: [
        {
          component: 'ProgramTabs',
          elements: [
            {
              component: 'ProgramTabElement',
              title: 'Tab title 1',
              componentModules: [
                {
                  component: 'BasicContent',
                  nesting: false,
                  icon: defaultBackgroundImage,
                  title: { content: 'Curabitur diam nulla', tag: 'h2' },
                  subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
                  intro:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
                  content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
                  image: {
                    component: 'LinkableImage',
                    file: { publicId: 'placeholders/griddo-background' },
                  },
                  button: {
                    component: 'Button',
                    buttonText: 'Link',
                    appearance: 'secondary',
                    url: {
                      href: '',
                      linkToURL: null,
                      newTab: false,
                      noFollow: false,
                    },
                  },
                  line: true,
                  lineType: 'L005',
                  layout: 'L001',
                  theme: 'light',
                },
              ],
            },
            {
              component: 'ProgramTabElement',
              title: 'Tab title 2',
              componentModules: [
                {
                  component: 'BasicContent',
                  nesting: false,
                  icon: defaultBackgroundImage,
                  title: { content: 'Curabitur diam nulla', tag: 'h2' },
                  subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
                  intro:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
                  content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
                  image: {
                    component: 'LinkableImage',
                    file: { publicId: 'placeholders/griddo-background' },
                  },
                  button: {
                    component: 'Button',
                    buttonText: 'Link',
                    appearance: 'secondary',
                    url: {
                      href: '',
                      linkToURL: null,
                      newTab: false,
                      noFollow: false,
                    },
                  },
                  line: true,
                  lineType: 'L005',
                  layout: 'L001',
                  theme: 'light',
                },
              ],
            },
            {
              component: 'ProgramTabElement',
              title: 'Tab title 3',
              componentModules: [
                {
                  component: 'BasicContent',
                  nesting: false,
                  icon: defaultBackgroundImage,
                  title: { content: 'Curabitur diam nulla', tag: 'h2' },
                  subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
                  intro:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
                  content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
                  image: {
                    component: 'LinkableImage',
                    file: { publicId: 'placeholders/griddo-background' },
                  },
                  button: {
                    component: 'Button',
                    buttonText: 'Link',
                    appearance: 'secondary',
                    url: {
                      href: '',
                      linkToURL: null,
                      newTab: false,
                      noFollow: false,
                    },
                  },
                  line: true,
                  lineType: 'L005',
                  layout: 'L001',
                  theme: 'light',
                },
              ],
            },
          ],
        },
      ],
    },
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [
        { ...ProgramMoreInfo.default },
        { ...ButtonRequestAdmission.default },
      ],
    },
  },

  thumbnails: {
    '1x': thumbnailsProgramDetail,
    '2x': thumbnailsProgramDetail,
  },
}
