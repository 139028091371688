import datapacksthumbnailsLandings from '@griddo-images/thumbnails/LandingMultiProgram.png'
import datapacksthumbnailsProjects from '@griddo-images/data-packs-thumbnails/Projects.png'
import datapacksthumbnailsGroups from '@griddo-images/data-packs-thumbnails/Groups.png'
import datapacksthumbnailsReports from '@griddo-images/data-packs-thumbnails/Reports.png'
import datapacksthumbnailsevents from '@griddo-images/data-packs-thumbnails/events.png'
import datapacksthumbnailsNewsCategories from '@griddo-images/data-packs-thumbnails/news_category.jpg'
import datapacksthumbnailsReportsCategories from '@griddo-images/data-packs-thumbnails/report_category.jpg'
import datapacksthumbnailsNews from '@griddo-images/data-packs-thumbnails/News.png'
import datapacksthumbnailsStaff from '@griddo-images/data-packs-thumbnails/Staff.png'
import datapacksthumbnailsBasic_components from '@griddo-images/data-packs-thumbnails/Basic_components.png'
import datapacksthumbnailsLegal from '@griddo-images/data-packs-thumbnails/Legal.png'
import datapacksthumbnailsTestimonials from '@griddo-images/data-packs-thumbnails/testimonials.jpg'
import datapacksthumbnailsProgramTypes from '@griddo-images/data-packs-thumbnails/program_type.jpg'
import datapacksthumbnailsArea from '@griddo-images/data-packs-thumbnails/area.jpg'
import datapacksthumbnailsPrograms from '@griddo-images/data-packs-thumbnails/Programs.png'
import datapacksthumbnailsODS from '@griddo-images/data-packs-thumbnails/ods.jpg'

export default {
  ODS: {
    title: 'ODS',
    category: 'ODS',
    description:
      'The Sustainable Development Goals or Global Goals are a collection of 17 interlinked global goals designed to be a "blueprint to achieve a better and more sustainable future for all".',
    image: datapacksthumbnailsODS,
  },
  PROGRAMS: {
    title: 'Programs',
    category: 'PROGRAMS',
    description: 'It allows to use Programs content type',
    image: datapacksthumbnailsPrograms,
  },
  AREA: {
    title: 'Area',
    category: 'AREA',
    description: 'It allows to use Area content type',
    image: datapacksthumbnailsArea,
  },
  PROGRAM_TYPES: {
    title: 'ProgramTypes',
    category: 'PROGRAM_TYPES',
    description: 'It allows to use Program Types content type',
    image: datapacksthumbnailsProgramTypes,
  },
  TESTIMONIALS: {
    title: 'Testimonials',
    category: 'TESTIMONIALS',
    description: 'It allows to use Testimonials content type',
    image: datapacksthumbnailsTestimonials,
  },
  CONTENT: {
    title: 'Content',
    category: 'CONTENT',
    description: 'It allows to use content type',
    image: datapacksthumbnailsLegal,
  },
  BASIC_COMPONENTS: {
    title: 'Basic Components',
    category: 'BASIC_COMPONENTS',
    description: 'It allows to use Basic Components content type',
    image: datapacksthumbnailsBasic_components,
  },
  STAFF: {
    title: 'Staff',
    category: 'STAFF',
    description: 'Template for a person from staff',
    image: datapacksthumbnailsStaff,
  },
  NEWS: {
    title: 'News',
    category: 'NEWS',
    description: 'It allows to use News content type',
    image: datapacksthumbnailsNews,
  },
  NEWS_CATEGORIES: {
    title: 'News categories',
    category: 'NEWS',
    description: 'It allows to use News content type',
    image: datapacksthumbnailsNewsCategories,
  },
  EVENTS: {
    title: 'Events',
    category: 'EVENTS',
    description: 'It allows to use Events content type',
    image: datapacksthumbnailsevents,
  },
  REPORTS: {
    title: 'Reports',
    category: 'REPORTS',
    description: 'It allows to use Reports content type',
    image: datapacksthumbnailsReports,
  },
  REPORTS_CATEGORIES: {
    title: 'Reports categories',
    category: 'REPORTS',
    description: 'It allows to use Reports categories content type',
    image: datapacksthumbnailsReportsCategories,
  },
  GROUPS: {
    title: 'Groups',
    category: 'GROUPS',
    description: 'It allows to use Groups content type',
    image: datapacksthumbnailsGroups,
  },
  PROJECTS: {
    title: 'Projects',
    category: 'PROJECTS',
    description: 'It allows to use Groups content type',
    image: datapacksthumbnailsProjects,
  },
  SEARCH_RESULTS: {
    title: 'Search Results',
    category: 'SEARCH_RESULTS',
    description: 'It allows to use Search Results content type',
    image: datapacksthumbnailsProjects,
  },
  BLOG: {
    title: 'Blog',
    category: 'BLOG',
    description: 'It allows to use News content type',
    image: datapacksthumbnailsNews,
  },
  LANDINGS: {
    title: 'Landings',
    category: 'LANDINGS',
    description: 'It allows to use Landings templates',
    image: datapacksthumbnailsLandings,
  },
}
