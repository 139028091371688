import thumbnailsGroupDetail from '@griddo-images/thumbnails/GroupDetail.png'

import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { basicModuleWhiteList } from '@schemas/modules'

export default {
  schemaType: 'template',
  displayName: 'Group Detail',
  component: 'GroupDetail',
  dataPacks: ['GROUPS'],
  type: { label: 'Group', value: 'groups', mode: 'detail' },
  content: [
    {
      title: 'Group Title',
      type: 'HeadingField',
      key: 'contentTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      humanReadable: true,
    },
    {
      title: 'Acronym',
      type: 'TextField',
      humanReadable: true,
      key: 'acronym',
      hideable: true,
    },
    {
      title: 'Main researcher',
      type: 'TextField',
      humanReadable: true,
      key: 'mainResearcher',
      hideable: true,
    },
    {
      title: 'Number of members',
      type: 'TextField',
      humanReadable: true,
      key: 'members',
      hideable: true,
    },
    {
      title: 'Co-Main researcher',
      type: 'TextField',
      humanReadable: true,
      key: 'coMainResearcher',
      hideable: true,
    },
    {
      title: 'Description',
      type: 'RichText',
      humanReadable: true,
      key: 'description',
      hideable: true,
    },
    {
      title: 'Researchers',
      type: 'ReferenceField',
      key: 'data',
      sources: [{structuredData: 'STAFF' }],
    },
    {
      title: 'Researchers distributor type',
      key: 'distributorType',
      type: 'ConditionalField',
      options: [
        {
          name: 'expandable',
          value: 'expandable',
          title: 'Expandable',
        },
        {
          name: 'fully',
          value: 'fully',
          title: 'Fully Visible Distributor',
        },
      ],
    },
    {
      title: 'See more button label',
      type: 'TextField',
      humanReadable: true,
      key: 'seeMoreButtonLabel',
      helptext:
        'This is not visible in detail page. Fill in this field if you want to add a link to the group page when it is displayed in distributors',
      hideable: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: basicModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'GroupDetail',
    contentTitle: { tag: 'h1', content: 'Title' },
    acronym: '',
    mainResearcher: '',
    coMainResearcher: '',
    description: '',
    distributorType: 'expandable',
    data: {
      mode: 'auto',
      order: 'alpha',
      sources: [{structuredData: 'STAFF' }],
    },

    seeMoreButtonLabel: null,
    mainSection: {
      component: 'Section',
      name: 'Related Content',
      modules: [],
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },
  thumbnails: {
    '1x': thumbnailsGroupDetail,
    '2x': thumbnailsGroupDetail,
  },
}
