import thumbnailsCardCollection from '@griddo-images/thumbnails/CardCollection.png'
import themesblueultraLightYellow from '@griddo-images/themes/blue-ultraLightYellow.png'
import themesbluelight from '@griddo-images/themes/blue-light.png'
import themeslightlightBlue from '@griddo-images/themes/light-lightBlue.png'
import themeslightblue from '@griddo-images/themes/light-blue.png'
import themeslightultraLightYellow from '@griddo-images/themes/light-ultraLightYellow.png'
import layoutsCardCollectionlayout004 from '@griddo-images/layouts/CardCollection/layout004.png'
import layoutsCardCollectionlayout003 from '@griddo-images/layouts/CardCollection/layout003.png'
import layoutsCardCollectionlayout002 from '@griddo-images/layouts/CardCollection/layout002.png'
import layoutsCardCollectionlayout001 from '@griddo-images/layouts/CardCollection/layout001.png'

import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Card Collection',
  component: 'CardCollection',
  category: 'collections',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nested',
          options: [
            {
              value: true,
              title: 'Nested module',
              name: 'yes',
            },
            {
              value: false,
              title: 'No nested',
              name: 'no',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'image',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Cards',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          key: 'elements',
          whiteList: ['BasicCard'],
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'L001',
              img: layoutsCardCollectionlayout001,
            },
            {
              value: 'L002',
              img: layoutsCardCollectionlayout002,
            },
            {
              value: 'L003',
              img: layoutsCardCollectionlayout003,
            },
            {
              value: 'L004',
              img: layoutsCardCollectionlayout004,
            },
          ],
          columns: 2,
        },
        {
          title: 'Style',
          key: 'style',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'light-ultraLightYellow',
              img: themeslightultraLightYellow,
            },
            {
              value: 'light-blue',
              img: themeslightblue,
            },
            {
              value: 'light-lightBlue',
              img: themeslightlightBlue,
            },
            {
              value: 'blue-light',
              img: themesbluelight,
            },
            {
              value: 'blue-ultraLightYellow',
              img: themesblueultraLightYellow,
            },
          ],
          columns: 8,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'CardCollection',
    image: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Button',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    kind: 'BasicCard',
    elements: [
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        detail: '',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        detail: '',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        detail: '',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
    layout: 'L002',
    style: 'light-ultraLightYellow',
    nested: false,
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsCardCollection,
    '2x': thumbnailsCardCollection,
  },
}
