import thumbnailsSpacer from '@griddo-images/thumbnails/Spacer.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'

export default {
  schemaType: 'module',
  displayName: 'Spacer',
  component: 'Spacer',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'config',
      fields: [
        {
          title: 'Size',
          type: 'RadioGroup',
          key: 'size',
          helptext: '',
          options: [
            {
              title: 'S',
              name: 'S',
              value: 'S',
            },
            {
              title: 'M',
              name: 'M',
              value: 'M',
            },
            {
              title: 'L',
              name: 'L',
              value: 'L',
            },
            {
              title: 'XL',
              name: 'XL',
              value: 'Xl',
            },
          ],
          hideable: false,
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'Spacer',
    theme: 'light',
    size: 'S',
  },

  thumbnails: {
    '1x': thumbnailsSpacer,
    '2x': thumbnailsSpacer,
  },
}
