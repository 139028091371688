export default {
  schemaType: 'component',
  displayName: 'Program Table',
  component: 'ProgramTable',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Program Subject',
          type: 'ComponentArray',
          whiteList: ['ProgramTableColumn'],
          mandatory: true,
          maxItems: 3,
          key: 'elements',
          helptext: 'Maximum 3 columns',
        },
      ],
    },
  ],

  default: {
    component: 'ProgramTable',
    elements: [
      {
        component: 'ProgramTableColumn',
        title: 'Nº Semestre',
        elements: [
          {
            component: 'ProgramTableRow',
            title: 'Asignatura',
            credits: '6 ECTS',
            type: 'Obligatoria',
          },
        ],
      },
      {
        component: 'ProgramTableColumn',
        title: 'Nº Semestre',
        elements: [
          {
            component: 'ProgramTableRow',
            title: 'Asignatura',
            credits: '6 ECTS',
            type: 'Obligatoria',
          },
        ],
      },
    ],
  },
}
