import thumbnailsFeaturedAudiovisual from '@griddo-images/thumbnails/FeaturedAudiovisual.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import { defaultBackgroundImage } from '@constants/dam'
import { breakpoints } from '@constants/theme'

export default {
  schemaType: 'module',
  displayName: 'Featured Audiovisual',
  component: 'FeaturedAudiovisual',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Video',
          type: 'ComponentContainer',
          whiteList: ['VideoComponent'],
          key: 'video',
          hideable: true,
          helptext: 'Recommended size: 1128x634',
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'FeaturedAudiovisual',
    kind: 'VideoComponent',
    video: {
      component: 'VideoComponent',
      url: '',
      thumbnail: {
        component: 'LinkableImage',
        file: defaultBackgroundImage,
        veil: 50,
      },
      responsive: [
        {
          breakpoint: breakpoints.default,
          width: '400px',
        },
        {
          breakpointName: 's',
          breakpoint: breakpoints.s,
          width: '600px',
        },
        {
          breakpointName: 'm',
          breakpoint: breakpoints.m,
          width: '900px',
        },
        {
          breakpointName: 'l',
          breakpoint: breakpoints.l,
          width: '1100px',
        },
        {
          breakpointName: 'xl',
          breakpoint: breakpoints.l,
          width: '1300px',
        },
      ],
    },
    theme: 'light',
  },

  thumbnails: {
    '1x': thumbnailsFeaturedAudiovisual,
    '2x': thumbnailsFeaturedAudiovisual,
  },
}
