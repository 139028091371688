// import thumbnailsImageComponent2x from '@griddo-images/thumbnails/ImageComponent@2x.png';
// import thumbnailsImageComponent from '@griddo-images/thumbnails/ImageComponent.png';

export default {
  schemaType: 'component',
  displayName: 'Image Component',
  component: 'ImageComponent',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        { title: 'File', type: 'ImageField', key: 'file' },
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
        {
          title: 'Alternative Text',
          type: 'TextField',
          humanReadable: true,
          key: 'alt',
        },
      ],
    },
  ],

  default: { component: 'ImageComponent' },

  thumbnails: {
    // '1x': thumbnailsImageComponent,
    // '2x': thumbnailsImageComponent2x,
  },
}
