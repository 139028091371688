const moduleCategories = [
  {
    label: 'Content',
    value: 'content',
  },
  {
    label: 'Collections',
    value: 'collections',
  },
  {
    label: 'Distributors',
    value: 'distributors',
  },
  {
    label: 'Footer',
    value: 'footer',
  },
  {
    label: 'Forms',
    value: 'forms',
  },
  {
    label: 'Header',
    value: 'header',
  },
  {
    label: 'Hero',
    value: 'hero',
  },
  {
    label: 'Interactive',
    value: 'interactive',
  },
  {
    label: 'Programs',
    value: 'programs',
  },
  {
    label: 'Research',
    value: 'research',
  },
  {
    label: 'Research',
    value: 'research',
  },
  {
    label: 'News And Events',
    value: 'news_and_events',
  },
  {
    label: 'Staff',
    value: 'staff',
  },
  {
    label: 'People',
    value: 'people',
  },
  {
    label: 'Search & Discover',
    value: 'search_and_discover',
  },
]

export { moduleCategories }
