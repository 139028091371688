import thumbnailsStudyPlan from '@griddo-images/thumbnails/StudyPlan.png'
import studyPlanScheme from './StudyPlan'

export default {
  schemaType: 'module',
  displayName: 'Landing Study Plan',
  component: 'LandingStudyPlan',
  category: 'programs',
  dataPacks: ['ODS', 'PROGRAMS'],
  configTabs: [
    ...studyPlanScheme.configTabs,
    {
      title: 'config',
      fields: [
        {
          title: 'First tab deployed',
          type: 'ConditionalField',
          key: 'deployed',
          mandatory: true,
          condition: true,
          options: [
            {
              value: false,
              title: 'No',
              name: 'no',
            },
            {
              value: true,
              title: 'Yes',
              name: 'yes',
            },
          ],
        },
      ],
    },
  ],

  default: {
    ...studyPlanScheme.default,
    deployed: false,
    component: 'LandingStudyPlan',
  },

  thumbnails: {
    '1x': thumbnailsStudyPlan,
    '2x': thumbnailsStudyPlan,
  },
}
