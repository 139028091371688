export default {
  schemaType: 'component',
  displayName: 'Program Card',
  component: 'ProgramCard',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'AREA' }],
          key: 'data',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'ProgramCard',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData: 'AREA' }],
      order: 'recent',
    },
  },
}
