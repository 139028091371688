import thumbnailsFeaturedBlock from '@griddo-images/thumbnails/FeaturedBlock.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Featured Block',
  component: 'FeaturedBlock',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: false,
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          hideable: true,
          helptext: 'Recommended size: 455x618',
        },
        {
          title: 'Image margin',
          type: 'CheckGroup',
          key: 'imageMargin',
          options: [
            {
              value: true,
              title: 'At top',
              name: 'top',
            },
            {
              value: true,
              title: 'At bottom',
              name: 'bottom',
            },
          ],
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          hideable: false,
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'FeaturedBlock',
    icon: defaultBackgroundImage,
    title: {
      content: 'Text',
      tag: 'h2',
    },
    theme: 'blue',
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsFeaturedBlock,
    '2x': thumbnailsFeaturedBlock,
  },
}
