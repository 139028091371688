// import thumbnailsButton from '@griddo-images/thumbnails/Button.png';
import buttonslink from '@griddo-images/buttons/link.png'
import buttonssecondary from '@griddo-images/buttons/secondary.png'
import buttonsprimary02 from '@griddo-images/buttons/primary02.png'
import buttonsprimary01 from '@griddo-images/buttons/primary01.png'

export default {
  schemaType: 'component',
  displayName: 'Button',
  component: 'Button',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Link or Modal',
          type: 'ConditionalField',
          key: 'isLink',
          condition: 'link',
          options: [
            {
              name: 'link',
              value: 'link',
              title: 'Link',
            },
            {
              name: 'modal',
              value: 'modal',
              title: 'Modal',
            },
          ],
          fields: [
            {
              title: 'URL',
              type: 'UrlField',
              key: 'url',
              advanced: true,
              mandatory: true,
              condition: 'link',
            },
            {
              title: 'Modal',
              type: 'ComponentArray',
              elementUniqueSelection: false,
              mandatory: true,
              maxItems: 1,
              key: 'elements',
              whiteList: ['RequestInfoForm', 'NewsletterForm', 'DownloadForm'],
              condition: 'modal',
            },
          ],
        },
        {
          title: 'Text',
          type: 'TextField',
          humanReadable: true,
          key: 'buttonText',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Appearance',
          key: 'appearance',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'primary01',
              img: buttonsprimary01,
            },
            {
              value: 'primary02',
              img: buttonsprimary02,
            },
            {
              value: 'secondary',
              img: buttonssecondary,
            },
            {
              value: 'link',
              img: buttonslink,
            },
          ],
          columns: 4,
        },
      ],
    },
  ],

  default: {
    component: 'Button',
    buttonText: 'Link',
    appearance: 'link',
    url: {
      href: '',
      linkToURL: null,
      newTab: false,
      noFollow: false,
    },
    isLink: 'link',
    elements: [],
  },

  thumbnails: {
    // '1x': thumbnailsButton,
    // '2x': thumbnailsButton,
  },
}
