import thumbnailsCarouselCollection from '@griddo-images/thumbnails/CarouselCollection.png'
import themesblueultraLightYellow from '@griddo-images/themes/blue-ultraLightYellow.png'
import themesbluelight from '@griddo-images/themes/blue-light.png'
import themeslightlightBlue from '@griddo-images/themes/light-lightBlue.png'
import themeslightblue from '@griddo-images/themes/light-blue.png'
import themeslightultraLightYellow from '@griddo-images/themes/light-ultraLightYellow.png'
import layoutsCarouselCollectionlayout003 from '@griddo-images/layouts/CarouselCollection/layout003.png'
import layoutsCarouselCollectionlayout002 from '@griddo-images/layouts/CarouselCollection/layout002.png'
import layoutsCarouselCollectionlayout001 from '@griddo-images/layouts/CarouselCollection/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Carousel Collection',
  component: 'CarouselCollection',
  category: 'collections',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: false,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              mandatory: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
            {
              title: 'Entry',
              type: 'TextField',
              humanReadable: true,
              key: 'entry',
              hideable: true,
              mandatory: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          mandatory: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Cards',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          key: 'elements',
          whiteList: ['BasicCard'],
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsCarouselCollectionlayout001,
            },
            {
              value: 'L002',
              img: layoutsCarouselCollectionlayout002,
            },
            {
              value: 'L003',
              img: layoutsCarouselCollectionlayout003,
            },
          ],
          columns: 2,
        },
        {
          title: 'Style',
          key: 'style',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'light-ultraLightYellow',
              img: themeslightultraLightYellow,
            },
            {
              value: 'light-blue',
              img: themeslightblue,
            },
            {
              value: 'light-lightBlue',
              img: themeslightlightBlue,
            },
            {
              value: 'blue-light',
              img: themesbluelight,
            },
            {
              value: 'blue-ultraLightYellow',
              img: themesblueultraLightYellow,
            },
          ],
          columns: 8,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'CarouselCollection',
    nesting: false,
    icon: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    entry:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    kind: 'BasicCard',
    elements: [
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description: '',
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description: '',
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'BasicCard',
        image: defaultBackgroundImage,
        title: { content: 'Lorem ipsum', tag: 'h2' },
        description: '',
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
    layout: 'L001',
    style: 'light-ultraLightYellow',
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsCarouselCollection,
    '2x': thumbnailsCarouselCollection,
  },
}
