import thumbnailsLandingProgram from '@griddo-images/thumbnails/LandingProgram.png'
import LandingHeroProgram from '@schemas/modules/LandingHeroProgram'
import { landingModuleWhiteList } from '@schemas/modules'
import ProgramMoreInfo from '@schemas/components/ProgramMoreInfo'

export default {
  schemaType: 'template',
  displayName: 'Program Landing',
  component: 'LandingProgram',
  dataPacks: ['LANDINGS'],
  category: 'Landings',
  type: {
    label: 'Landings',
    value: 'landings',
  },
  content: [
    {
      title: 'Hero Section',
      type: 'ComponentArray',
      maxItems: 1,
      whiteList: ['LandingHeroProgram'],
      key: 'heroSection',
      mandatory: true,
    },
    {
      title: 'Type Product',
      type: 'ReferenceField',
      sources: [{structuredData: 'PROGRAM_LANDING' }],
      key: 'data',
      mandatory: true,
      fullRelations: true,
      maxItems: 1,
      minItems: 1,
    },
    {
      title: 'Program Tabs',
      type: 'ComponentArray',
      whiteList: ['LandingProgramTabs'],
      key: 'programTabs',
      maxItems: 1,
      mandatory: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: landingModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'LandingProgram',
    heroSection: {
      component: 'Section',
      name: 'Hero Section',
      modules: [{ ...LandingHeroProgram.default }],
      sectionPosition: 1,
    },
    programTitle: { content: 'Title', tag: 'h2' },
    programTabs: {
      component: 'Section',
      name: 'Program Tabs',
      modules: [
        {
          component: 'LandingProgramTabs',
          elements: [
            {
              component: 'LandingProgramTabElement',
              title: 'Tab title 1',
              componentModules: [
                {
                  component: 'BasicContent',
                  nesting: false,
                  icon: {
                    component: 'LinkableImage',
                    alt: '',
                    publicId: 'placeholders/griddo-background',
                    url: '',
                  },
                  title: { content: 'Curabitur diam nulla', tag: 'h2' },
                  subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
                  intro:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
                  content:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
                  image: {
                    component: 'LinkableImage',
                    file: { publicId: 'placeholders/griddo-background' },
                  },
                  button: {
                    component: 'Button',
                    buttonText: 'Link',
                    appearance: 'secondary',
                    url: {
                      href: '',
                      linkToURL: null,
                      newTab: false,
                      noFollow: false,
                    },
                  },
                  line: true,
                  lineType: 'L005',
                  layout: 'L001',
                  theme: 'light',
                },
              ],
            },
          ],
        },
      ],
    },
    hasDistributorData: true,
    data: {
      mode: 'manual',
      sources: [{ structuredData: 'PROGRAM_LANDING' }],
      order: 'recent',
      fullRelations: true,
      quantity: 1,
    },
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...ProgramMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsLandingProgram,
    '2x': thumbnailsLandingProgram,
  },
}
