export default {
  PROGRAM_LANDING: {
    title: 'Program landings',
    dataPacks: ['LANDINGS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
        {
          title: 'ID',
          type: 'TextField',
          humanReadable: true,
          key: 'identifier',
        },
        {
          title: 'ID Dedication',
          type: 'TextField',
          humanReadable: true,
          key: 'idDedication',
        },
        {
          title: 'ID Campus',
          type: 'TextField',
          humanReadable: true,
          key: 'idCampus',
        },
        {
          title: 'Programs Type',
          type: 'AsyncCheckGroup',
          source: 'PROGRAM_TYPES',
          key: 'programType',
        },
        {
          title: 'Area',
          type: 'AsyncCheckGroup',
          source: 'AREA',
          key: 'area',
        },
        {
          title: 'Mode',
          type: 'RadioGroup',
          key: 'mode',
          options: [
            {
              title: 'Online',
              name: 'Online',
              value: 'Online',
            },
            {
              title: 'Presencial',
              name: 'Presencial',
              value: 'Presencial',
            },
            {
              title: 'Mixto',
              name: 'Mixto',
              value: 'Mixto',
            },
          ],
        },
        {
          title: 'Language',
          type: 'RadioGroup',
          key: 'language',
          options: [
            {
              title: 'Castellano',
              name: 'Castellano',
              value: 'Castellano',
            },
            {
              title: 'Francés',
              name: 'Francés',
              value: 'Francés',
            },
            {
              title: 'Inglés',
              name: 'Inglés',
              value: 'Inglés',
            },
            {
              title: 'Catalán',
              name: 'Catalán',
              value: 'Catalán',
            },
          ],
        },
        {
          title: 'Price',
          type: 'TextField',
          humanReadable: true,
          key: 'price',
        },
        {
          title: 'Location',
          type: 'RadioGroup',
          key: 'location',
          options: [
            {
              title: 'Nacional',
              name: 'national',
              value: 'national',
            },
            {
              title: 'Latam',
              name: 'latam',
              value: 'latam',
            },
            {
              title: 'Francofona',
              name: 'francofona',
              value: 'francofona',
            },
          ],
        },
      ],
    },
  },
  ODS: {
    title: 'ODS',
    dataPacks: ['PROGRAMS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Number',
          type: 'TextField',
          humanReadable: true,
          key: 'number',
        },
        {
          title: 'Name',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
        {
          title: 'Color',
          type: 'ColorPicker',
          colors: [
            '#e5243b',
            '#dda83a',
            '#4c9f38',
            '#c5192d',
            '#ff3a21',
            '#26bde2',
            '#fcc30b',
            '#a21942',
            '#fd6925',
            '#dd1367',
            '#fd9d24',
            '#bf8b2e',
            '#3f7e44',
            '#0a97d9',
            '#56c02b',
            '#00689d',
            '#19486a',
          ],
          defaultValue: {
            color: '#e5243b',
          },
          key: 'color',
        },
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },
  AREA: {
    title: 'Area',
    dataPacks: ['PROGRAMS', 'EVENTS', 'LANDINGS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
        {
          title: 'Color',
          type: 'ColorPicker',
          colors: [
            '#e5243b',
            '#dda83a',
            '#4c9f38',
            '#c5192d',
            '#ff3a21',
            '#26bde2',
            '#fcc30b',
            '#a21942',
            '#fd6925',
            '#dd1367',
            '#fd9d24',
            '#bf8b2e',
            '#3f7e44',
            '#0a97d9',
            '#56c02b',
            '#00689d',
            '#19486a',
          ],
          defaultValue: {
            color: '#e5243b',
          },
          key: 'color',
        },
      ],
    },
  },
  PROGRAM_TYPES: {
    title: 'Program Types',
    dataPacks: ['PROGRAMS', 'LANDINGS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Program Type',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
      ],
    },
  },
  TESTIMONIALS: {
    title: 'Testimonials',
    dataPacks: ['TESTIMONIALS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
        {
          title: 'Position',
          type: 'TextField',
          humanReadable: true,
          key: 'position',
        },
        {
          title: 'Quote',
          type: 'TextField',
          humanReadable: true,
          key: 'quote',
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
        },
      ],
    },
  },
  PROGRAMS: {
    title: 'Program',
    dataPacks: ['PROGRAMS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['ProgramDetail'],
      fields: [
        {
          title: 'ID',
          type: 'TextField',
          humanReadable: true,
          key: 'identifier',
          from: 'identifier',
        },
        {
          title: 'ID Dedication',
          type: 'TextField',
          humanReadable: true,
          key: 'idDedication',
          from: 'idDedication',
        },
        {
          title: 'ID Campus',
          type: 'TextField',
          humanReadable: true,
          key: 'idCampus',
          from: 'idCampus',
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'programTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'programTitle',
        },
        {
          title: 'Detail',
          type: 'TextField',
          humanReadable: true,
          key: 'detail',
          from: 'detail',
        },
        {
          title: 'Programs Type',
          type: 'AsyncCheckGroup',
          source: 'PROGRAM_TYPES',
          key: 'programType',
          from: 'programType',
        },
        {
          title: 'Area',
          type: 'AsyncCheckGroup',
          source: 'AREA',
          key: 'area',
          from: 'area',
        },
        {
          title: 'ODS',
          type: 'AsyncCheckGroup',
          source: 'ODS',
          key: 'ods',
          from: 'ods',
        },
        {
          title: 'Start',
          type: 'TextField',
          humanReadable: true,
          key: 'start',
          from: 'start',
        },
        {
          title: 'Duration',
          type: 'TextField',
          humanReadable: true,
          key: 'duration',
          from: 'duration',
        },
        {
          title: 'Mode',
          type: 'RadioGroup',
          key: 'mode',
          from: 'mode',
        },
        {
          title: 'Language',
          type: 'RadioGroup',
          key: 'language',
          from: 'language',
        },
        {
          title: 'Main Tag',
          type: 'TextField',
          humanReadable: true,
          key: 'mainTag',
          from: 'mainTag',
        },
        {
          title: 'Distributor Image',
          type: 'ImageField',
          key: 'distributorImage',
          from: 'distributorImage',
        },
        {
          title: 'Hero Image',
          type: 'ImageField',
          key: 'image',
          from: 'image',
        },
        {
          title: 'Price',
          type: 'TextField',
          humanReadable: true,
          key: 'price',
          from: 'price',
        },
      ],
      searchFrom: ['start'],
    },
    clone: null,
    defaultValues: null,
  },
  CONTENT: {
    title: 'Content',
    dataPacks: ['CONTENT'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['LegalTemplate'],
      fields: [
        {
          title: 'ID',
          type: 'TextField',
          humanReadable: true,
          key: 'identifier',
          mandatory: true,
          from: 'identifier',
        },
        {
          title: 'Legal Title',
          type: 'HeadingField',
          key: 'legalTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'legalTitle',
        },
        {
          title: 'Body',
          type: 'RichText',
          key: 'description',
          from: 'description',
          html: true,
        },
      ],
      searchFrom: ['legalTitle'],
    },
    clone: null,
    defaultValues: null,
  },
  BASIC_COMPONENTS: {
    title: 'Basic Components',
    dataPacks: ['BASIC_COMPONENTS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['ThankYou'],
      fields: [
        {
          title: 'ID',
          type: 'TextField',
          humanReadable: true,
          key: 'identifier',
          mandatory: true,
          from: 'identifier',
        },
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          from: 'icon',
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'legalTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          from: 'title',
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          humanReadable: true,
          key: 'subtitle',
          from: 'subtitle',
        },
        {
          title: 'Description',
          type: 'TextField',
          humanReadable: true,
          key: 'description',
          from: 'description',
        },
        {
          title: 'CTA',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          from: 'button',
        },
        {
          title: 'Complement',
          type: 'ConditionalField',
          key: 'complement',
          mandatory: true,
          condition: true,
          from: 'complement',
          options: [
            {
              name: 'image',
              value: true,
              title: 'Image',
            },
            {
              name: 'option',
              value: false,
              title: 'Option',
            },
          ],
          fields: [
            {
              title: 'CTA',
              type: 'ComponentContainer',
              elementUniqueSelection: false,
              maxItems: 1,
              whiteList: ['ImageComponent'],
              key: 'imageComponent',
              condition: true,
            },
            {
              title: 'CTA',
              type: 'ComponentContainer',
              elementUniqueSelection: false,
              maxItems: 1,
              whiteList: ['BasicCard'],
              key: 'basicCard',
              condition: false,
            },
          ],
        },
      ],
    },
    clone: null,
    defaultValues: null,
  },
  STAFF: {
    title: 'Staff',
    dataPacks: ['STAFF'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['StaffDetail'],
      fields: [
        {
          title: 'Staff Name',
          type: 'TextField',
          humanReadable: true,
          key: 'staffTitle',
          from: 'staffTitle',
        },
        {
          title: 'Summary',
          type: 'RichText',
          key: 'summary',
          from: 'summary',
        },
        // {
        //   title: 'Staff Type',
        //   type: 'RadioGroup',
        //   key: 'staffType',
        //   from: 'staffType',
        // },
        {
          title: 'Staff Type',
          type: 'AsyncCheckGroup',
          source: 'STAFF_TYPE',
          key: 'staffTypeRelation',
          from: 'staffTypeRelation',
        },
        {
          title: 'Staff Position',
          type: 'TextField',
          humanReadable: true,
          key: 'staffPosition',
          from: 'staffPosition',
        },
        {
          title: 'Staff Photo',
          type: 'ImageField',
          key: 'staffPhoto',
          from: 'staffPhoto',
        },
        {
          type: 'SliderField',
          step: 1,
          minvalue: 0,
          maxvalue: 100,
          key: 'staffPhotoY',
          from: 'staffPhotoY',
          title: 'Vertical shift',
          default: 50,
        },
        {
          type: 'SliderField',
          step: 1,
          minvalue: 0,
          maxvalue: 100,
          key: 'staffPhotoX',
          from: 'staffPhotoX',
          title: 'Horizontal shift',
          default: 50,
        },
        {
          title: 'Title 1',
          type: 'TextField',
          humanReadable: true,
          key: 'professionalExperienceTitle',
          from: 'professionalExperienceTitle',
          hideable: true,
        },
        {
          title: 'Text 1',
          type: 'RichText',
          key: 'professionalExperience',
          from: 'professionalExperience',
          hideable: true,
          html: true,
        },
        {
          title: 'Title 2',
          type: 'TextField',
          humanReadable: true,
          key: 'teachingExperienceTitle',
          from: 'teachingExperienceTitle',
          hideable: true,
        },
        {
          title: 'Text 2',
          type: 'RichText',
          key: 'teachingExperience',
          from: 'teachingExperience',
          hideable: true,
          html: true,
        },
        {
          title: 'Title 3',
          type: 'TextField',
          humanReadable: true,
          key: 'subjectsTitle',
          from: 'subjectsTitle',
          hideable: true,
        },
        {
          title: 'Text 3',
          type: 'RichText',
          key: 'subjects',
          from: 'subjects',
          hideable: true,
          html: true,
        },
        {
          title: 'Email',
          type: 'TextField',
          humanReadable: true,
          key: 'email',
          from: 'email',
        },
        {
          title: 'Linkedin',
          type: 'TextField',
          humanReadable: true,
          key: 'linkedin',
          from: 'linkedin',
          helpText: 'Linkedin URL (without https://)',
        },
        {
          title: 'Quote',
          type: 'TextField',
          humanReadable: true,
          key: 'quote',
          from: 'quote',
        },
        {
          title: 'Related Content',
          type: 'ComponentArray',
          key: 'mainSection',
          from: 'mainSection',
        },
      ],
      searchFrom: ['staffTitle', 'staffPosition'],
    },
    clone: null,
    defaultValues: null,
  },
  STAFF_TYPE: {
    title: 'Staff Type',
    dataPacks: ['STAFF'],
    local: false,
    taxonomy: false,
    translate: true,
    fromPage: false,
    schema: {
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
      ],
    },
  },
  NEWS: {
    title: 'News',
    dataPacks: ['NEWS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['NewsDetail'],
      fields: [
        {
          title: 'News Title',
          type: 'HeadingField',
          key: 'newsTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'newsTitle',
        },
        {
          title: 'Category',
          type: 'AsyncCheckGroup',
          source: 'NEWS_CATEGORIES',
          key: 'category',
          from: 'category',
        },
        {
          title: 'News Entry',
          type: 'RichText',
          key: 'entry',
          from: 'entry',
        },
        {
          title: 'Author',
          type: 'TextField',
          humanReadable: true,
          key: 'author',
          from: 'author',
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          from: 'image',
        },
        {
          title: 'News Body',
          type: 'RichText',
          key: 'body',
          from: 'body',
        },
        {
          title: 'Publication Date',
          type: 'DateField',
          key: 'publishedDate',
          from: 'publishedDate',
          indexable: true,
        },
        {
          title: 'News Hour',
          type: 'TextField',
          humanReadable: true,
          key: 'hour',
          from: 'hour',
        },
        {
          title: 'Summary Content',
          type: 'RichText',
          key: 'summary',
          from: 'summary',
        },
        {
          title: 'Modified Date',
          type: 'DateField',
          key: 'modifiedDate',
          from: 'modifiedDate',
          indexable: true,
        },
        {
          title: 'Related Content',
          type: 'ComponentArray',
          key: 'mainSection',
          from: 'mainSection',
        },
      ],
      searchFrom: ['newsTitle'],
    },
    clone: null,
    defaultValues: null,
  },
  NEWS_CATEGORIES: {
    title: 'News Categories',
    dataPacks: ['NEWS', 'BLOG'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Category',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
      ],
    },
  },
  EVENTS: {
    title: 'Events',
    dataPacks: ['EVENTS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['EventDetail'],
      fields: [
        {
          title: 'Event Title',
          type: 'TextField',
          humanReadable: true,
          key: 'eventTitle',
          from: 'eventTitle',
        },
        {
          title: 'Main image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'mainImage',
          from: 'mainImage',
        },
        {
          title: 'Summary',
          type: 'TextField',
          humanReadable: true,
          key: 'eventSummary',
          from: 'eventSummary',
          mandatory: true,
          helptext: 'For distributors',
        },
        {
          title: 'Status',
          type: 'RadioGroup',
          key: 'eventStatus',
          from: 'eventStatus',
          mandatory: true,
        },
        {
          title: 'Date',
          type: 'DateField',
          key: 'eventDate',
          from: 'eventDate',
        },
        {
          title: 'Time',
          type: 'TextField',
          humanReadable: true,
          key: 'eventTime',
          from: 'eventTime',
          hideable: true,
        },
        {
          title: 'Mode',
          type: 'RadioGroup',
          key: 'eventMode',
          from: 'eventMode',
          mandatory: true,
        },
        {
          title: 'Type',
          type: 'RadioGroup',
          key: 'eventType',
          from: 'eventType',
          mandatory: true,
        },
        {
          title: 'Language',
          type: 'RadioGroup',
          key: 'eventLang',
          from: 'eventLang',
          mandatory: true,
        },
        {
          title: 'Related Content',
          type: 'ComponentArray',
          key: 'mainSection',
          from: 'mainSection',
        },
        {
          title: 'Area',
          type: 'AsyncCheckGroup',
          source: 'AREA',
          key: 'area',
          from: 'area',
        },
        {
          title: 'Topic',
          type: 'AsyncCheckGroup',
          source: 'EVENTS_TOPICS',
          key: 'eventTopic',
          from: 'eventTopic',
        },
      ],
      searchFrom: ['eventTitle'],
    },
    clone: null,
    defaultValues: null,
  },
  EVENTS_TOPICS: {
    title: 'Events Topics',
    dataPacks: ['EVENTS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Topic name',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
      ],
    },
  },
  REPORTS: {
    title: 'Reports',
    dataPacks: ['REPORTS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['ReportDetail'],
      fields: [
        {
          title: 'Report Title',
          type: 'HeadingField',
          key: 'reportTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'reportTitle',
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          helptext: 'Recommended size: 760x430',
          from: 'image',
        },
        {
          title: 'Summary',
          type: 'RichText',
          key: 'summary',
          from: 'summary',
        },
        {
          title: 'Author',
          type: 'TextField',
          humanReadable: true,
          key: 'author',
          from: 'author',
        },
        {
          title: 'Publication Date',
          type: 'DateField',
          key: 'publishedDate',
          from: 'published',
          indexable: true,
        },
        {
          title: 'News Hour',
          type: 'TextField',
          humanReadable: true,
          key: 'hour',
          from: 'hour',
        },
        {
          title: 'Report Topic',
          type: 'AsyncCheckGroup',
          source: 'REPORT_CATEGORIES',
          key: 'category',
          from: 'category',
        },
        {
          title: 'Report Entry',
          type: 'TextField',
          humanReadable: true,
          key: 'entry',
          from: 'entry',
        },
        {
          title: 'Report Body',
          type: 'Wysiwyg',
          humanReadable: true,
          key: 'body',
          html: true,
          from: 'body',
        },
        {
          title: 'Related Content',
          type: 'ComponentArray',
          key: 'mainSection',
          from: 'mainSection',
        },
      ],
      searchFrom: ['reportTitle'],
    },
    clone: null,
    defaultValues: null,
  },
  REPORT_CATEGORIES: {
    title: 'Report Categories',
    dataPacks: ['REPORTS'],
    local: false,
    taxonomy: false,
    fromPage: false,
    translate: true,
    schema: {
      fields: [
        {
          title: 'Category',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
      ],
    },
  },
  GROUPS: {
    title: 'Groups',
    dataPacks: ['GROUPS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['GroupDetail'],
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'contentTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'contentTitle',
        },
        {
          title: 'Acronym',
          type: 'TextField',
          humanReadable: true,
          key: 'acronym',
          from: 'acronym',
        },
        {
          title: 'Main researcher',
          type: 'TextField',
          humanReadable: true,
          key: 'mainResearcher',
          from: 'mainResearcher',
        },
        {
          title: 'Number of members',
          type: 'TextField',
          humanReadable: true,
          key: 'members',
          from: 'members',
        },
        {
          title: 'Co-Main researcher',
          type: 'TextField',
          humanReadable: true,
          key: 'coMainResearcher',
          from: 'coMainResearcher',
        },
        {
          title: 'Researchers distributor type',
          key: 'distributorType',
          from: 'distributorType',
          type: 'ConditionalField',
          options: [
            {
              name: 'expandable',
              value: 'expandable',
              title: 'Expandable',
            },
            {
              name: 'fully',
              value: 'fully',
              title: 'Fully Visible Distributor',
            },
          ],
        },
        {
          title: 'Description',
          type: 'RichText',
          key: 'description',
          from: 'description',
        },

        {
          title: 'See more button label',
          type: 'TextField',
          humanReadable: true,
          key: 'seeMoreButtonLabel',
          from: 'seeMoreButtonLabel',
          hideable: true,
        },
      ],
      searchFrom: ['contentTitle'],
    },
    clone: null,
    defaultValues: null,
  },
  PROJECTS: {
    title: 'Projects',
    dataPacks: ['PROJECTS'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['ProjectDetail'],
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'contentTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'contentTitle',
        },
        {
          title: 'Acronym',
          type: 'TextField',
          humanReadable: true,
          key: 'acronym',
          from: 'acronym',
        },
        {
          title: 'Initial Date',
          type: 'DateField',
          key: 'initialDate',
          from: 'initialDate',
        },
        {
          title: 'Final Date',
          type: 'DateField',
          key: 'finalDate',
          from: 'finalDate',
        },
        {
          title: 'Keywords',
          type: 'TextField',
          humanReadable: true,
          key: 'keywords',
          from: 'keywords',
        },
        {
          title: 'Main researcher',
          type: 'TextField',
          humanReadable: true,
          key: 'mainResearcher',
          from: 'mainResearcher',
        },
        {
          title: 'Budget',
          type: 'TextField',
          humanReadable: true,
          key: 'budget',
          from: 'budget',
        },
        {
          title: 'Researchers distributor type',
          key: 'distributorType',
          from: 'distributorType',
          type: 'ConditionalField',
          options: [
            {
              name: 'expandable',
              value: 'expandable',
              title: 'Expandable',
            },
            {
              name: 'fully',
              value: 'fully',
              title: 'Fully Visible Distributor',
            },
          ],
        },
        {
          title: 'Description',
          type: 'RichText',
          key: 'description',
          from: 'description',
        },

        {
          title: 'See more button label',
          type: 'TextField',
          humanReadable: true,
          key: 'seeMoreButtonLabel',
          from: 'seeMoreButtonLabel',
          hideable: true,
        },
      ],
      searchFrom: ['contentTitle'],
    },
    clone: null,
    defaultValues: null,
  },
  BLOG: {
    title: 'Blog',
    dataPacks: ['BLOG'],
    local: false,
    taxonomy: false,
    fromPage: true,
    translate: true,
    schema: {
      templates: ['BlogDetail'],
      fields: [
        {
          title: 'Blog Entry Title',
          type: 'HeadingField',
          key: 'newsTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          from: 'newsTitle',
        },
        {
          title: 'Category',
          type: 'AsyncCheckGroup',
          source: 'NEWS_CATEGORIES',
          key: 'category',
          from: 'category',
        },
        {
          title: 'Blog Entry',
          type: 'RichText',
          key: 'entry',
          from: 'entry',
        },
        {
          title: 'Author',
          type: 'TextField',
          humanReadable: true,
          key: 'author',
          from: 'author',
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          from: 'image',
        },
        {
          title: 'Body',
          type: 'RichText',
          key: 'body',
          from: 'body',
        },
        {
          title: 'Publication Date',
          type: 'DateField',
          key: 'publishedDate',
          from: 'publishedDate',
          indexable: true,
        },
        {
          title: 'Hour',
          type: 'TextField',
          humanReadable: true,
          key: 'hour',
          from: 'hour',
        },
        {
          title: 'Summary Content',
          type: 'RichText',
          key: 'summary',
          from: 'summary',
        },
        {
          title: 'Modified Date',
          type: 'DateField',
          key: 'modifiedDate',
          from: 'modifiedDate',
          indexable: true,
        },
        {
          title: 'Related Content',
          type: 'ComponentArray',
          key: 'mainSection',
          from: 'mainSection',
        },
      ],
      searchFrom: ['newsTitle'],
    },
    clone: null,
    defaultValues: null,
  },
}
