export const getCampaignCode = (url) => {
  const startIdx = url.indexOf('?c=')
  if (startIdx === -1) return
  const temp = url.slice(startIdx + 3)
  if (temp.includes('&')) {
    return temp.slice(0, temp.indexOf('&'))
  } else {
    return temp.slice(0, temp.length)
  }
}

export const getSearchQuery = (url) => {
  const startIdx = url.indexOf('?searchquery=')
  if (startIdx === -1) return

  const temp = url.slice(startIdx + 1)?.split('=')

  if (temp?.length > 0) return decodeURI(temp[1])

  return ''
}
