// Mandatory templates
import BasicTemplate from './BasicTemplate'
import Error404 from './Error404'
import ProgramDetail from './ProgramDetail'

import ApplyForm from './ApplyForm'
import StaffDetail from './StaffDetail'
import ProgramList from './ProgramList'
import StaffList from './StaffList'

// User templates
import Sitemap from './Sitemap'

import LegalTemplate from './LegalTemplate'
import ThankYou from './ThankYou'
import NewsDetail from './NewsDetail'
import NewsList from './NewsList'

import EventDetail from './EventDetail'
import EventList from './EventList'
import ContactTemplate from './ContactTemplate'
import ReportDetail from './ReportDetail'
import ReportsList from './ReportsList'
import SearchResults from './SearchResults'

import GroupDetail from './GroupDetail'
import ProjectDetail from './ProjectDetail'

import BlogDetail from './BlogDetail'
import BlogList from './BlogList'

import LandingProgram from './LandingProgram'
import LandingMultiProgram from './LandingMultiProgram'

export default {
  BasicTemplate,
  Sitemap,
  Error404,
  ProgramDetail,
  StaffDetail,
  StaffList,
  LegalTemplate,
  ApplyForm,
  ThankYou,
  ProgramList,
  NewsDetail,
  NewsList,
  EventDetail,
  EventList,
  ContactTemplate,
  ReportDetail,
  ReportsList,
  SearchResults,
  GroupDetail,
  ProjectDetail,
  BlogDetail,
  BlogList,
  LandingProgram,
  LandingMultiProgram,
}
