import thumbnailsColumnsPresentation from '@griddo-images/thumbnails/ColumnsPresentation.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Columns Presentation',
  component: 'ColumnsPresentation',
  dataPacks: null,
  category: 'interactive',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: false,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          condition: false,
          humanReadable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          hideable: false,
          helptext: 'Recommended size: 840x726',
        },
        {
          title: 'Element',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          minItems: 1,
          key: 'elements',
          whiteList: ['MainColumnElement'],
          hideable: false,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
          ],
          columns: 8,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'ColumnsPresentation',
    nesting: false,
    icon: defaultBackgroundImage,
    image: defaultBackgroundImage,
    title: {
      content: 'Text',
      tag: 'h2',
    },
    subtitle: 'Text',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',

    kind: 'MainColumnElement',
    elements: [
      {
        component: 'MainColumnElement',
        label: 'Element',
        kind: 'SecondaryColumnElement',
        elements: [
          {
            component: 'SecondaryColumnElement',
            image: defaultBackgroundImage,
            label: 'Element',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
            button: {
              component: 'Button',
              buttonText: 'Link',
              appearance: 'secondary',
              isLink: 'link',
              elements: [],
              url: {
                href: '',
                linkToURL: null,
                newTab: false,
                noFollow: false,
              },
            },
          },
        ],
      },
    ],
    line: true,
    theme: 'ultraLightBlue',
  },

  thumbnails: {
    '1x': thumbnailsColumnsPresentation,
    '2x': thumbnailsColumnsPresentation,
  },
}
