export default {
  schemaType: 'component',
  displayName: 'Program Table Row',
  component: 'ProgramTableRow',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
          mandatory: true,
        },
        {
          title: 'Credits',
          type: 'TextField',
          humanReadable: true,
          key: 'credits',
          hideable: true,
        },
        {
          title: 'Type',
          type: 'TextField',
          humanReadable: true,
          key: 'type',
          hideable: true,
        },
        {
          title: 'ODS List',
          type: 'ReferenceField',
          sources: [{ structuredData: 'ODS' }],
          key: 'data',
          mandatory: false,
          helptext: 'You can only choose one ODS',
        },
        {
          title: 'File',
          type: 'FileField',
          key: 'file',
        },
      ],
    },
  ],

  default: {
    component: 'ProgramTableRow',
    title: 'Asignatura',
    credits: '6 ECTS',
    type: 'Obligatoria',
    hasDistributorData: true,
    data: {
      mode: 'manual',
      sources: [{ structuredData: 'ODS' }],
      order: 'alpha',
      quantity: 1,
    },
    file: '',
  },
}
