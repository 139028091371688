import thumbnailsLandingMultiProgram from '@griddo-images/thumbnails/LandingMultiProgram.png'
import LandingHeroProgram from '@schemas/modules/LandingHeroProgram'
import { landingModuleWhiteList } from '@schemas/modules'
import LandingBasicMoreInfo from '@schemas/components/LandingBasicMoreInfo'

const wWhiteList = [...landingModuleWhiteList, `LandingProgramCollection`]

export default {
  schemaType: 'template',
  displayName: 'Multi Program Landing',
  component: 'LandingMultiProgram',
  dataPacks: ['LANDINGS'],
  category: 'Landings',
  type: {
    label: 'Landings',
    value: 'landings',
  },
  content: [
    {
      title: 'Hero Section',
      type: 'ComponentArray',
      maxItems: 1,
      whiteList: ['LandingHeroProgram'],
      key: 'heroSection',
      mandatory: true,
    },
    {
      title: 'Program Tabs',
      type: 'ComponentArray',
      whiteList: ['LandingProgramTabs'],
      key: 'programTabs',
      maxItems: 1,
      mandatory: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: wWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['LandingBasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'LandingMultiProgram',
    heroSection: {
      component: 'Section',
      name: 'Hero Section',
      modules: [{ ...LandingHeroProgram.default }],
      sectionPosition: 1,
    },
    programTitle: { content: 'Title', tag: 'h2' },
    programTabs: {
      component: 'Section',
      name: 'Program Tabs',
      modules: [
        {
          component: 'LandingProgramTabs',
          elements: [
            {
              component: 'LandingProgramTabElement',
              title: 'Tab title 1',
              componentModules: [
                {
                  component: 'LandingProgramCollection',
                  icon: {
                    alt: '',
                    publicId: 'placeholders/griddo-background',
                  },
                  title: {
                    content: 'Title',
                    tag: 'h2',
                  },
                  subtitle: {
                    content: 'Subtitle',
                    tag: 'h2',
                  },
                  detail:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
                  elements: [
                    {
                      component: 'LandingProgramCard',
                      image: {
                        alt: '',
                        publicId: 'placeholders/griddo-background',
                      },
                      title: {
                        content: 'Title',
                        tag: 'h2',
                      },
                      detail:
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
                      start: '¡Start date!',
                      duration: 'Duration',
                      mode: 'Online',
                      language: 'Castellano',
                      btnLabel: '¡Inscríbete ya!',
                      hasDistributorData: true,
                      data: {
                        mode: 'manual',
                        sources: [{structuredData: 'AREA' }],
                        order: 'recent',
                        quantity: 1,
                        fullRelations: true,
                      },
                    },
                    {
                      component: 'LandingProgramCard',
                      image: {
                        alt: '',
                        publicId: 'placeholders/griddo-background',
                      },
                      title: {
                        content: 'Title',
                        tag: 'h2',
                      },
                      detail:
                        'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
                      start: 'Start date',
                      duration: 'Duration',
                      mode: 'Online',
                      language: 'Castellano',
                      btnLabel: '¡Inscríbete ya!',
                      hasDistributorData: true,
                      data: {
                        mode: 'manual',
                        sources: [{structuredData: 'AREA' }],
                        order: 'recent',
                        quantity: 1,
                        fullRelations: true,
                      },
                    },
                  ],
                  line: true,
                  layout: 'vertical',
                },
              ],
            },
          ],
        },
      ],
    },
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...LandingBasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsLandingMultiProgram,
    '2x': thumbnailsLandingMultiProgram,
  },
}
