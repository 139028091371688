// import thumbnailsLinkableImage2x from '@griddo-images/thumbnails/LinkableImage@2x.png';
// import thumbnailsLinkableImage from '@griddo-images/thumbnails/LinkableImage.png';

import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Linkable Image',
  component: 'LinkableImage',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        { title: 'File', type: 'ImageField', key: 'file' },
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
        },
        {
          title: 'Alternative Text',
          type: 'TextField',
          humanReadable: true,
          key: 'alt',
        },
        { title: 'Veil percentage', type: 'SliderField', key: 'veil' },
        { title: 'Link image', type: 'UrlField', advanced: true, key: 'url' },
      ],
    },
  ],

  default: {
    component: 'LinkableImage',
    file: defaultBackgroundImage,
    veil: 50,
  },

  thumbnails: {
    // '1x': thumbnailsLinkableImage,
    // '2x': thumbnailsLinkableImage2x,
  },
}
