import thumbnailsError404 from '@griddo-images/thumbnails/Error404.png'

import MixedCollection from '@schemas/modules/MixedCollection'

export default {
  displayName: 'Error 404',
  component: 'Error404',
  dataPacks: null,
  type: { label: 'Static', value: 'static', special: '404' },
  singleInstance: true,
  schemaType: 'template',

  content: [
    {
      title: 'Error Title',
      type: 'HeadingField',
      key: 'errorTitle',
      default: { tag: 'h1', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      humanReadable: true,
    },
    {
      title: 'Error Subtitle',
      type: 'TextField',
      humanReadable: true,
      key: 'subtitle',
      mandatory: true,
    },
    {
      title: 'Button',
      type: 'ComponentContainer',
      whiteList: ['Button'],
      key: 'button',
      mandatory: true,
    },
    {
      title: 'Decoration',
      type: 'ConditionalField',
      key: 'line',
      options: [
        {
          value: true,
          title: 'Line',
          name: 'yes',
        },
        {
          value: false,
          title: 'No Line',
          name: 'no',
        },
      ],
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: [
        'AccordionCollection',
        'BasicContent',
        'CardCollection',
        'CypherCollection',
        'DownloadCollection',
        'MultimediaContent',
        'ColumnText',
        'BasicText',
        'Spacer',
        'StudyPlan',
        'Testimonials',
        'HighlightedContent',
        'FeaturedBlock',
        'FeaturedAudiovisual',
        'GoToTop',
        'HorizontalTabs',
        'CarouselCollection',
        'VerticalTabs',
        'MixedCollection',
        'ColumnsPresentation',
        'ProgramsDistributor',
        'Grid',
        'LogoCollection',
        'SocialShare',
        'StaffDistributor',
        'NewsDistributor',
      ],
      key: 'relatedContent',
    },
  ],

  default: {
    type: 'template',
    templateType: 'Error404',
    errorTitle: {
      content: 'Página no encontrada',
      tag: 'h1',
    },
    subtitle: 'Lo sentimos, la página que buscas no existe.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '/',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    line: true,
    relatedContent: {
      component: 'Section',
      name: 'Related Content',
      modules: [{ ...MixedCollection.default }],
      sectionPosition: 2,
    },
  },

  thumbnails: {
    '1x': thumbnailsError404,
    '2x': thumbnailsError404,
  },
}
