import thumbnailsHighlightedHeroElement from '@griddo-images/thumbnails/HighlightedHeroElement.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslight from '@griddo-images/themes/light.png'
import layoutsHighlightedHeroElementlayout002 from '@griddo-images/layouts/HighlightedHeroElement/layout002.png'
import layoutsHighlightedHeroElementlayout001 from '@griddo-images/layouts/HighlightedHeroElement/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Highlighted Hero Element',
  component: 'HighlightedHeroElement',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: false,
          humanReadable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },

        {
          title: 'Link',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Image',
          type: 'ComponentContainer',
          whiteList: ['LinkableImage'],
          key: 'image',
          mandatory: true,
          hideable: true,
          helptext: 'Recommended size: 552x789',
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsHighlightedHeroElementlayout001,
            },
            {
              value: 'L002',
              img: layoutsHighlightedHeroElementlayout002,
            },
          ],
          columns: 2,
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'blue',
              img: themesblue,
            },
          ],
          columns: 8,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'HighlightedHeroElement',
    title: {
      content: 'Text',
      tag: 'h2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    image: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
    },
    layout: 'L001',
    theme: 'light',
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsHighlightedHeroElement,
    '2x': thumbnailsHighlightedHeroElement,
  },
}
