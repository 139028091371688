import thumbnailsInteractiveBasicContent from '@griddo-images/thumbnails/InteractiveBasicContent.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Interactive Basic Content',
  component: 'InteractiveBasicContent',
  dataPacks: null,
  category: 'interactive',
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'image',
          hideable: true,
          condition: false,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          html: true,
          hideable: true,
        },
        {
          title: 'Cards',
          type: 'ComponentArray',
          mandatory: true,
          maxItems: 4,
          key: 'elements',
          whiteList: ['InteractiveCard'],
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },
  ],

  default: {
    component: 'InteractiveBasicContent',
    image: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Button',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    elements: [
      {
        component: 'InteractiveCard',
        image: defaultBackgroundImage,
        title: { content: 'Title', tag: 'h2' },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'InteractiveCard',
        image: defaultBackgroundImage,
        title: { content: 'Title', tag: 'h2' },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'InteractiveCard',
        image: defaultBackgroundImage,
        title: { content: 'Title', tag: 'h2' },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
  },

  thumbnails: {
    '1x': thumbnailsInteractiveBasicContent,
    '2x': thumbnailsInteractiveBasicContent,
  },
}
