import thumbnailsRequestInfoForm from '@griddo-images/thumbnails/RequestInfoForm.png'

export default {
  schemaType: 'module',
  displayName: 'Request Info Form',
  component: 'RequestInfoForm',
  dataPacks: null,
  category: 'forms',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          value: {
            text: 'Se utilizará la configuración de template',
          },
          type: 'NoteField',
          key: 'noteFilters',
        },
      ],
    },
  ],

  default: {
    component: 'RequestInfoForm',
  },

  thumbnails: {
    '1x': thumbnailsRequestInfoForm,
    '2x': thumbnailsRequestInfoForm,
  },
}
