// import thumbnailsVideoHeroElement2x from '@griddo-images/thumbnails/VideoHeroElement@2x.png';
// import thumbnailsVideoHeroElement from '@griddo-images/thumbnails/VideoHeroElement.png';

import { defaultBackgroundImage } from '@constants/dam'
import { breakpoints } from '@constants/theme'

export default {
  schemaType: 'component',
  displayName: 'Video Hero Element',
  component: 'VideoHeroElement',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: false,
          humanReadable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Link',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Video',
          type: 'ComponentArray',
          whiteList: ['VideoComponent'],
          key: 'elements',
          condition: false,
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: 1,
          hideable: true,
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'VideoHeroElement',
    title: {
      content: 'Text',
      tag: 'h2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    kind: 'VideoComponent',
    elements: [
      {
        component: 'VideoComponent',
        url: '',
        thumbnail: {
          component: 'LinkableImage',
          file: defaultBackgroundImage,
          veil: 75,
        },
        responsive: [
          {
            breakpoint: breakpoints.default,
            width: '400px',
          },
          {
            breakpointName: 's',
            breakpoint: breakpoints.s,
            width: '600px',
          },
          {
            breakpointName: 'm',
            breakpoint: breakpoints.m,
            width: '900px',
          },
          {
            breakpointName: 'l',
            breakpoint: breakpoints.l,
            width: '1100px',
          },
          {
            breakpointName: 'xl',
            breakpoint: breakpoints.l,
            width: '1300px',
          },
        ],
      },
    ],
    line: true,
  },

  thumbnails: {
    // '1x': thumbnailsVideoHeroElement,
    // '2x': thumbnailsVideoHeroElement2x,
  },
}
