export default {
  schemaType: 'component',
  displayName: 'ProgramList Card',
  component: 'ProgramListCard',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
    },
  ],

  default: {
    component: 'ProgramListCard',
  },
}
