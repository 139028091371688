import thumbnailsProgramDetail from '@griddo-images/thumbnails/ProgramDetail.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Landing Hero Program',
  component: 'HeroProgram',
  dataPacks: null,
  category: 'hero',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Program Title',
          type: 'HeadingField',
          key: 'programTitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          helptext: 'Maximum 80 characters',
          humanReadable: true,
        },
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'backgroundImage',
          helptext: 'Recommended size: 1920x1080',
          mandatory: true,
        },
        {
          title: 'USP',
          type: 'ComponentContainer',
          whiteList: ['ProgramUsp'],
          key: 'programUsp',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'TextField',
          humanReadable: true,
          key: 'detail',
          mandatory: true,
          helptext: 'Maximum 220 characters',
        },
        {
          title: 'Main Tag',
          type: 'TextField',
          humanReadable: true,
          key: 'mainTag',
          hideable: true,
        },
        {
          title: 'Additional Quick Fackts',
          type: 'ComponentArray',
          whiteList: ['LandingQuickFact'],
          key: 'elements',
          hideable: true,
        },
      ],
    },
  ],
  default: {
    component: 'LandingHeroProgram',
    programTitle: { content: 'Title', tag: 'h2' },
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 60,
    },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
    mainTag: 'Últimas plazas',
    programUsp: {
      component: 'ProgramUsp',
      icon: defaultBackgroundImage,
      usp: 'Titulación Oficial Europea',
    },
    elements: [],
  },

  thumbnails: {
    '1x': thumbnailsProgramDetail,
    '2x': thumbnailsProgramDetail,
  },
}
