import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Main Column Element',
  component: 'MainColumnElement',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Label',
          type: 'TextField',
          humanReadable: true,
          key: 'label',
          hideable: false,
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          hideable: true,
        },
        {
          title: 'Element',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          minItems: 1,
          key: 'elements',
          whiteList: ['SecondaryColumnElement'],
        },
      ],
    },
  ],

  default: {
    component: 'MainColumnElement',
    label: 'Element',
    kind: 'SecondaryColumnElement',
    elements: [
      {
        component: 'SecondaryColumnElement',
        image: defaultBackgroundImage,
        label: 'Element',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'secondary',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
  },
}
