export default {
  schemaType: 'component',
  displayName: 'Program Accordion',
  component: 'ProgramAccordion',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Program Accordion elements',
          type: 'ComponentArray',
          whiteList: ['ProgramAccordionElement'],
          key: 'elements',
          maxItems: null,
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: 'ProgramAccordion',
    elements: [
      {
        component: 'ProgramAccordionElement',
        title: { content: 'Primer Curso', tag: 'h2' },
        credits: '60 ECTS',
        programTable: {
          component: 'ProgramTable',
          elements: [
            {
              component: 'ProgramTableColumn',
              title: 'Nº Semestre',
              elements: [
                {
                  component: 'ProgramTableRow',
                },
              ],
            },
          ],
        },
      },
    ],
  },
}
