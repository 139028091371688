// Mandatory modules
import Header from './Header'
import Footer from './Footer'
import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter'

// User modules
import AccordionCollection from './AccordionCollection'
import BasicContent from './BasicContent'
import CardCollection from './CardCollection'
import ColumnText from './ColumnText'
import CypherCollection from './CypherCollection'
import DownloadCollection from './DownloadCollection'
import MultimediaContent from './MultimediaContent'
import BasicText from './BasicText'
import Spacer from './Spacer'
import HeroCompact from './HeroCompact'
import StudyPlan from './StudyPlan'
import Testimonials from './Testimonials'
import HighlightedContent from './HighlightedContent'
import FeaturedBlock from './FeaturedBlock'
import FeaturedAudiovisual from './FeaturedAudiovisual'
import GoToTop from './GoToTop'
import HorizontalTabs from './HorizontalTabs'
import CarouselCollection from './CarouselCollection'
import ProgramTabs from './ProgramTabs'
import VerticalTabs from './VerticalTabs'
import ColumnsPresentation from './ColumnsPresentation'
import MixedCollection from './MixedCollection'
import HeroCarousel from './HeroCarousel'
import IntroForm from './IntroForm'
import ProgramsDistributor from './ProgramsDistributor'
import LogoCollection from './LogoCollection'
import Grid from './Grid'
import RequestInfoForm from './RequestInfoForm'
import NewsletterForm from './NewsletterForm'
import DownloadForm from './DownloadForm'
import EventForm from './EventForm'
import PrivacyForm from './PrivacyForm'
import SocialShare from './SocialShare'
import NewsDistributor from './NewsDistributor'
import StaffDistributor from './StaffDistributor'
import StaffDistributorSimple from '../modules/StaffDistributorSimple'
import SimpleHero from './SimpleHero'
import SpeakersCollection from './SpeakersCollection'
import EventDistributor from './EventDistributor'
import InteractiveBasicContent from './InteractiveBasicContent'
import ReportsDistributor from './ReportsDistributor'
import ColouredCollection from './ColouredCollection'
import GroupsDistributor from './GroupsDistributor'
import ProjectsDistributor from './ProjectsDistributor'
import ProgramFilter from './ProgramFilter'
import FAQCollection from './FAQCollection'
import BlogDistributor from './BlogDistributor'
import LandingProgramCollection from './LandingProgramCollection'
import LandingHeroProgram from './LandingHeroProgram'
import LandingProgramTabs from './LandingProgramTabs'
import LandingStudyPlan from './LandingStudyPlan'

export default {
  AccordionCollection,
  BasicContent,
  CardCollection,
  ColumnText,
  CypherCollection,
  DownloadCollection,
  Footer,
  Header,
  MultimediaContent,
  BasicText,
  Spacer,
  HeroCompact,
  StudyPlan,
  Testimonials,
  HighlightedContent,
  FeaturedBlock,
  FeaturedAudiovisual,
  GoToTop,
  HorizontalTabs,
  CarouselCollection,
  VerticalTabs,
  ColumnsPresentation,
  ProgramTabs,
  MixedCollection,
  HeroCarousel,
  ProgramsDistributor,
  LogoCollection,
  Grid,
  RequestInfoForm,
  NewsletterForm,
  DownloadForm,
  EventForm,
  PrivacyForm,
  SocialShare,
  NewsDistributor,
  StaffDistributor,
  StaffDistributorSimple,
  SimpleHero,
  IntroForm,
  SpeakersCollection,
  EventDistributor,
  InteractiveBasicContent,
  ReportsDistributor,
  ColouredCollection,
  GroupsDistributor,
  ProjectsDistributor,
  ProgramFilter,
  FAQCollection,
  BlogDistributor,
  LandingHeroProgram,
  LandingProgramTabs,
  LandingProgramCollection,
  LandingStudyPlan,
  LandingFooter,
  LandingHeader,
}

export const basicModuleWhiteList = [
  'AccordionCollection',
  'BasicContent',
  'CardCollection',
  'CypherCollection',
  'DownloadCollection',
  'MultimediaContent',
  'ColumnText',
  'BasicText',
  'Spacer',
  'StudyPlan',
  'Testimonials',
  'HighlightedContent',
  'FeaturedBlock',
  'FeaturedAudiovisual',
  'GoToTop',
  'HorizontalTabs',
  'CarouselCollection',
  'VerticalTabs',
  'MixedCollection',
  'ColumnsPresentation',
  'ProgramsDistributor',
  'Grid',
  'LogoCollection',
  'SocialShare',
  'StaffDistributor',
  'NewsDistributor',
  'EventDistributor',
  'InteractiveBasicContent',
  'ReportsDistributor',
  'ColouredCollection',
  'GroupsDistributor',
  'ProjectsDistributor',
  'ProgramFilter',
  'FAQCollection',
  'BlogDistributor',
]

export const landingModuleWhiteList = [
  'AccordionCollection',
  'BasicContent',
  'BasicText',
  'CardCollection',
  'CarouselCollection',
  'ColouredCollection',
  'CypherCollection',
  'DownloadCollection',
  'FeaturedAudiovisual',
  'FeaturedBlock',
  'GoToTop',
  'HorizontalTabs',
  'LogoCollection',
  'Testimonials',
  'LandingStudyPlan',
]
