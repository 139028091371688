import thumbnailsGoToTop from '@griddo-images/thumbnails/GoToTop.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'

export default {
  schemaType: 'module',
  displayName: 'Go To Top',
  component: 'GoToTop',
  dataPacks: null,
  category: 'content',
  configTabs: [
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'GoToTop',
    theme: 'light',
  },

  thumbnails: {
    '1x': thumbnailsGoToTop,
    '2x': thumbnailsGoToTop,
  },
}
