export default {
  ODS: { title: 'ODS' },
  PROGRAMS: { title: 'Programs' },
  AREA: { title: 'Area' },
  PROGRAM_TYPES: { title: 'Program Types' },
  TESTIMONIALS: { title: 'Testimonials' },
  CONTENT: { title: 'Content' },
  BASIC_COMPONENTS: { title: 'Basic Components' },
  STAFF: { title: 'Staff' },
  NEWS: { title: 'News' },
  NEWS_CATEGORIES: { title: 'News categories' },
  EVENTS: { title: 'Events' },
  REPORTS: { title: 'Reports' },
  REPORTS_CATEGORIES: { title: 'Reports categories' },
  GROUPS: { title: 'Groups' },
  PROJECTS: { title: 'Projects' },
  SEARCH_RESULTS: { title: 'Search Results' },
  BLOG: { title: 'Blog' },
  LANDINGS: { title: 'Landings' },
}
