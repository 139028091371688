import thumbnailsNewsletterForm from '@griddo-images/thumbnails/NewsletterForm.png'
import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'primary01'
defaultBtn.buttonText = 'Suscribirme'

export default {
  schemaType: 'module',
  displayName: 'Newsletter Form',
  component: 'NewsletterForm',
  dataPacks: null,
  category: 'forms',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          humanReadable: true,
          key: 'subtitle',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'TextField',
          humanReadable: true,
          key: 'description',
          hideable: true,
        },
        {
          title: 'CTA',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: false,
        },
      ],
    },
  ],

  default: {
    component: 'NewsletterForm',
    title: 'Suscríbete a nuestra newsletter',
    subtitle: 'Déjanos tus datos',
    description: 'Toda la información directamente en tu correo.',
    buttonText: 'Suscribirme',
    button: { ...defaultBtn },
  },

  thumbnails: {
    '1x': thumbnailsNewsletterForm,
    '2x': thumbnailsNewsletterForm,
  },
}
