const PAGES_SECTIONS = {
  HOME: 'home',
  PRODUCT: 'producto',
  RESULTS: 'resultados',
  BLOG: 'blog',
  ERROR: 'error',
  THANKS: 'gracias',
  CHECKOUT: 'checkout',
  CORPORATE: 'corporativo',
  LANDING_PRODUCT: 'landing_producto',
  LANDING_CATEGORY: 'landing_producto',
}

const PAGES_TYPES = { DETAIL: 'detalle', CATEGORY: 'categoría' }

const DATALAYER_MODULES = {
  MENU: 'menu',
  HEADER: 'header',
  FOOTER: 'footer',
  BODY: 'cuerpo',
  SIDE: 'lateral',
}

const DATALAYER_CUSTOM_EVENTS = {
  PAGE_VIEW: 'page_view',
  SELECT_ITEM: 'select_item',
  VIEW_ITEM: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  PURCHASE: 'purchase',
  GENERATE_LEAD: 'generate_lead',
  SELECT_CONTENT: 'select_content',
  VIEW_CONTENT: 'view_content',
  SEARCH: 'search',
  VIEW_SEARCH_RESULTS: 'view_search_results',
  CALL_TO_PHONE: 'call_to_phone',
  FILE_DOWNLOAD: 'file_download',
}

const DATALAYER_CONTENT_TYPES = {
  EVENT: 'evento',
  NEW: 'noticia',
  STAFF: 'porfolio',
  EXPERT: 'expertos',
  REPORT: 'reports',
  BASIC_CONTENT: 'basic_content',
}

const getCampaignCode = () => {
  const params = new URLSearchParams(window.location.search)
  const campaignCode = params.get('c')
  if (campaignCode && campaignCode !== '') return campaignCode

  const _campaignCode = { code: 'I90700M0001' }
  if (window.document.referrer) {
    const groups = ['google', 'yahoo', 'msnlive', 'ask', 'bing', 'babylon']

    const refererDomain = window.document.referrer.split('://')
    const domain = refererDomain[1].split('.')

    let domainGroup = domain[0] === 'www' ? domain[1] : domain[0]
    const indexGroup = groups.indexOf(domainGroup)

    if (indexGroup !== -1 && groups[indexGroup] === domainGroup) {
      _campaignCode.code = 'I90701M0001'
    }
  }

  const cookies = window.document?.cookie?.split(';')

  if (cookies?.length > 0) {
    const code = cookies
      ?.map((c) => {
        const cookieSplit = c.split(/=(.*)/s)
        if ('utm_campaign' === cookieSplit[0].trim()) {
          const utm_campaign = JSON.parse(cookieSplit[1])
          return utm_campaign?.utm_campaign
            ? window.atob(utm_campaign.utm_campaign)
            : ''
        }
      })
      .filter((e) => e)

    if (code?.length > 0 && code[0] !== 'I90700M0001')
      _campaignCode.code = code[0]
  }

  return _campaignCode.code
}

const GET_CAMPAIGN_CODE_FUNCTION = `__SCRIPT:(${getCampaignCode})()`

const DataLayerMaster = ({ locale, eventName = 'page_view', isCX = false }) => {
  const _locale = locale.replace('_', '-').split('-')[0] || locale

  return {
    event: 'custom_event',
    event_name: eventName,
    brand: 'ucma',
    country_site: _locale,
    language_site: _locale,
    platform: 'site_principal',
    promomodalidad: isCX ? GET_CAMPAIGN_CODE_FUNCTION : getCampaignCode(),
  }
}

const DataLayerCustomEventsExtraParams = {
  file_name: undefined,
  file_extension: undefined,
  form_type: undefined,
  form_id: undefined,
  lead_id: undefined,
  module: undefined,
  product_category: undefined,
  product_category_2: undefined,
  product_id: undefined,
  product_name: undefined,
  search_term: undefined,
  social_network: undefined,
  transaction_id: undefined,
  client: undefined,
  content_author: undefined,
  content_category: undefined,
  content_title: undefined,
  content_type: undefined,
  ecommerce: {
    items: [
      {
        item_name: undefined,
        item_id: undefined,
        item_category: undefined,
        item_category_2: undefined,
        item_list_name: undefined,
        quantity: undefined,
        currency: undefined,
        price: undefined,
      },
    ],
  },
  value: undefined,
  id_error: undefined,
}

const mountParamsPage = ({
  locale,
  isHome = false,
  templateType,
  pathname,
  eventName,
  isCX = false,
}) => {
  const result = DataLayerMaster({ locale, eventName, isCX })

  if (isHome) {
    result.page_section = PAGES_SECTIONS.HOME
    result.page_type = PAGES_TYPES.DETAIL
  } else {
    switch (templateType) {
      case 'BasicTemplate':
      case 'LegalTemplate':
      case 'ContactTemplate':
      case 'ApplyForm':
        result.page_section = PAGES_SECTIONS.CORPORATE
        result.page_type = PAGES_TYPES.DETAIL
        break
      case 'ProgramDetail':
        result.page_section = PAGES_SECTIONS.PRODUCT
        result.page_type = PAGES_TYPES.DETAIL
        break
      case 'ProgramList':
        result.page_section = PAGES_SECTIONS.PRODUCT
        result.page_type = PAGES_TYPES.CATEGORY
        break
      case 'Error404':
        result.page_section = PAGES_SECTIONS.ERROR
        result.page_type = PAGES_TYPES.DETAIL
        break
      case 'NewsDetail':
      case 'BlogDetail':
      case 'EventDetail':
      case 'StaffDetail':
      case 'ReportDetail':
        result.page_section = PAGES_SECTIONS.BLOG
        result.page_type = PAGES_TYPES.DETAIL
        break
      case 'NewsList':
      case 'BlogList':
      case 'EventList':
      case 'StaffList':
      case 'ReportsList':
        result.page_section = PAGES_SECTIONS.BLOG
        result.page_type = PAGES_TYPES.CATEGORY
        break
      case 'ThankYou':
        result.page_section = PAGES_SECTIONS.THANKS
        result.page_type = PAGES_TYPES.DETAIL
        break
      case 'SearchResults':
        result.page_section = PAGES_SECTIONS.RESULTS
        result.page_type = PAGES_TYPES.CATEGORY
        break
      case 'LandingMultiProgram':
        result.page_section = PAGES_SECTIONS.LANDING_CATEGORY
        result.page_type = PAGES_TYPES.CATEGORY
        break
      case 'LandingProgram':
        result.page_section = PAGES_SECTIONS.LANDING_PRODUCT
        result.page_type = PAGES_TYPES.DETAIL
        break
      default:
        result.page_section = PAGES_SECTIONS.CORPORATE
        result.page_type = PAGES_TYPES.DETAIL
        break
    }
  }

  result.page_name = `${result.page_section}/${result.page_type}${pathname}`
  return result
}

const mountCustomEvent = ({ eventName }) => {
  return {
    event: 'custom_event',
    event_name: eventName,
  }
}

export {
  PAGES_SECTIONS,
  PAGES_TYPES,
  DATALAYER_MODULES,
  DATALAYER_CUSTOM_EVENTS,
  DATALAYER_CONTENT_TYPES,
  GET_CAMPAIGN_CODE_FUNCTION,
  mountParamsPage,
  mountCustomEvent,
  DataLayerMaster,
  getCampaignCode,
  DataLayerCustomEventsExtraParams,
}
