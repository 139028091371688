import thumbnailsMultimediaContent from '@griddo-images/thumbnails/MultimediaContent.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import layoutsMultimediaContentlayout002 from '@griddo-images/layouts/MultimediaContent/layout002.png'
import layoutsMultimediaContentlayout001 from '@griddo-images/layouts/MultimediaContent/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'
import { breakpoints } from '@constants/theme'

export default {
  schemaType: 'module',
  displayName: 'Multimedia Content',
  component: 'MultimediaContent',
  dataPacks: null,
  category: 'content',
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Show Image or Video?',
          type: 'ConditionalField',
          key: 'nested',
          mandatory: true,
          options: [
            {
              value: true,
              title: 'Image',
              name: 'image',
            },
            {
              value: false,
              title: 'Video',
              name: 'video',
            },
          ],
          fields: [
            {
              title: 'Image',
              type: 'ComponentContainer',
              whiteList: ['LinkableImage'],
              key: 'linkableimage',
              condition: true,
              elementUniqueSelection: true,
              mandatory: true,
              maxItems: 1,
              helptext: 'Recommended size: 1920x1080',
            },
            {
              title: 'Video',
              type: 'ComponentArray',
              whiteList: ['VideoComponent'],
              key: 'elements',
              condition: false,
              elementUniqueSelection: true,
              mandatory: true,
              maxItems: 1,
            },
          ],
        },
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: true,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              mandatory: false,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
            {
              title: 'Intro',
              type: 'TextField',
              humanReadable: true,
              key: 'intro',
              hideable: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: false,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Content',
          type: 'RichText',
          humanReadable: true,
          key: 'content',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Anchor ID',
          type: 'TextField',
          humanReadable: true,
          key: 'anchorID',
          prefix: '#',
          hideable: true,
          helptext:
            'Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.',
        },
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsMultimediaContentlayout001,
            },
            {
              value: 'L002',
              img: layoutsMultimediaContentlayout002,
            },
          ],
          columns: 2,
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'MultimediaContent',
    nesting: false,
    icon: defaultBackgroundImage,
    title: { content: 'Curabitur diam nulla', tag: 'h2' },
    subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
    intro: 'Lorem ipsum dolor sit amet.',
    content: 'Lorem ipsum dolor sit amet.',
    linkableimage: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
      veil: 50,
    },
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    kind: 'VideoComponent',
    elements: [
      {
        component: 'VideoComponent',
        url: '',
        thumbnail: {
          component: 'LinkableImage',
          file: defaultBackgroundImage,
          veil: 50,
        },
        responsive: [
          {
            breakpoint: breakpoints.default,
            width: '400px',
          },
          {
            breakpointName: 's',
            breakpoint: breakpoints.s,
            width: '600px',
          },
          {
            breakpointName: 'm',
            breakpoint: breakpoints.m,
            width: '900px',
          },
          {
            breakpointName: 'l',
            breakpoint: breakpoints.l,
            width: '1100px',
          },
          {
            breakpointName: 'xl',
            breakpoint: breakpoints.l,
            width: '1300px',
          },
        ],
      },
    ],
    layout: 'L001',
    theme: 'light',
    nested: false,
  },

  thumbnails: {
    '1x': thumbnailsMultimediaContent,
    '2x': thumbnailsMultimediaContent,
  },
}
