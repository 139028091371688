import thumbnailsLegalTemplate from '@griddo-images/thumbnails/LegalTemplate.png'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'

export default {
  schemaType: 'template',
  displayName: 'Legal Template',
  component: 'LegalTemplate',
  dataPacks: ['CONTENT'],
  type: { label: 'Content', value: 'content', mode: 'detail' },

  content: [
    {
      title: 'Legal Title',
      type: 'HeadingField',
      key: 'legalTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      hideable: false,
      humanReadable: true,
    },
    {
      title: 'Body',
      type: 'Wysiwyg',
      humanReadable: true,
      key: 'description',
      hideable: false,
      html: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: ['DownloadCollection', 'FeaturedBlock', 'GoToTop'],
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'LegalTemplate',
    mainSection: {
      component: 'Section',
      name: 'Related Content',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsLegalTemplate,
    '2x': thumbnailsLegalTemplate,
  },
}
