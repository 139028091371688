import es from './es.json'
import ca from './ca.json'
import en from './en.json'
import fr from './fr.json'

export default {
  es: { ...es },
  ca: { ...ca },
  en: { ...en },
  fr: { ...fr },
}
