import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'primary01'
defaultBtn.buttonText = 'Enviar'

export default {
  schemaType: 'module',
  displayName: 'Privacy Form',
  component: 'PrivacyForm',
  dataPacks: null,
  category: 'forms',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          humanReadable: true,
          key: 'title',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'TextField',
          humanReadable: true,
          key: 'description',
          hideable: true,
        },
        {
          title: 'CTA',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: false,
        },
      ],
    },
  ],

  default: {
    component: 'PrivacyForm',
    title: 'Tu privacidad nos importa.',
    description:
      'Por eso, te recordamos que puedes marcar las siguientes casillas para darnos tu consentimiento para:',
    button: { ...defaultBtn },
  },
}
