import thumbnailsBasicContent from '@griddo-images/thumbnails/BasicContent.png'
import layoutsBasicContentlinesD05 from '@griddo-images/layouts/BasicContent/lines/D05.png'
import layoutsBasicContentlinesD04 from '@griddo-images/layouts/BasicContent/lines/D04.png'
import layoutsBasicContentlinesD03 from '@griddo-images/layouts/BasicContent/lines/D03.png'
import layoutsBasicContentlinesD02 from '@griddo-images/layouts/BasicContent/lines/D02.png'
import layoutsBasicContentlinesD01 from '@griddo-images/layouts/BasicContent/lines/D01.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import layoutsBasicContentlayout004 from '@griddo-images/layouts/BasicContent/layout004.png'
import layoutsBasicContentlayout003 from '@griddo-images/layouts/BasicContent/layout003.png'
import layoutsBasicContentlayout002 from '@griddo-images/layouts/BasicContent/layout002.png'
import layoutsBasicContentlayout001 from '@griddo-images/layouts/BasicContent/layout001.png'

import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Basic Content',
  component: 'BasicContent',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: true,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              mandatory: false,
              hideable: true,
              condition: false,
              humanReadable: true,
            },
            {
              title: 'Intro',
              type: 'TextField',
              humanReadable: true,
              key: 'intro',
              hideable: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Content',
          type: 'RichText',
          humanReadable: true,
          key: 'content',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Image',
          type: 'ComponentContainer',
          whiteList: ['LinkableImage'],
          key: 'image',
          mandatory: true,
          helptext: 'Recommended size: 552x520',
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Anchor ID',
          type: 'TextField',
          humanReadable: true,
          key: 'anchorID',
          prefix: '#',
          hideable: true,
          helptext:
            'Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.',
        },
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsBasicContentlayout001,
            },
            {
              value: 'L002',
              img: layoutsBasicContentlayout002,
            },
            {
              value: 'L003',
              img: layoutsBasicContentlayout003,
            },
            {
              value: 'L004',
              img: layoutsBasicContentlayout004,
            },
          ],
          columns: 2,
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          mandatory: true,
          condition: true,
          options: [
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
          ],
          fields: [
            {
              title: 'Type',
              key: 'lineType',
              type: 'VisualUniqueSelection',
              condition: true,
              options: [
                {
                  value: 'L001',
                  img: layoutsBasicContentlinesD01,
                },
                {
                  value: 'L002',
                  img: layoutsBasicContentlinesD02,
                },
                {
                  value: 'L003',
                  img: layoutsBasicContentlinesD03,
                },
                {
                  value: 'L004',
                  img: layoutsBasicContentlinesD04,
                },
                {
                  value: 'L005',
                  img: layoutsBasicContentlinesD05,
                },
              ],
              columns: 8,
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'BasicContent',
    nesting: false,
    icon: defaultBackgroundImage,
    title: { content: 'Curabitur diam nulla', tag: 'h2' },
    subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
    intro:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
    image: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
    },
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    line: true,
    lineType: 'L005',
    layout: 'L001',
    theme: 'light',
  },

  thumbnails: {
    '1x': thumbnailsBasicContent,
    '2x': thumbnailsBasicContent,
  },
}
