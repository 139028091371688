import thumbnailsNewsDetail from '@griddo-images/thumbnails/NewsDetail.png'

import { getCurrentDate } from '@utils/CurrentDate'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import SocialShare from '@schemas/modules/SocialShare'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Blog Detail',
  component: 'BlogDetail',
  dataPacks: ['BLOG'],
  type: { label: 'BLOG', value: 'blog', mode: 'detail' },

  content: [
    {
      title: 'Entry Title',
      type: 'HeadingField',
      key: 'newsTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      humanReadable: true,
    },
    {
      title: 'Entry',
      type: 'TextField',
      humanReadable: true,
      key: 'entry',
    },
    {
      title: 'Author',
      type: 'TextField',
      humanReadable: true,
      key: 'author',
    },
    {
      title: 'Category',
      type: 'AsyncCheckGroup',
      source: 'NEWS_CATEGORIES',
      key: 'category',
      mandatory: true,
    },
    {
      title: 'Image',
      type: 'ImageField',
      key: 'image',
      mandatory: true,
      helptext: 'Recommended size: 744x420',
    },
    {
      title: 'Body',
      type: 'Wysiwyg',
      humanReadable: true,
      key: 'body',
      html: true,
    },
    {
      title: 'Publication Date',
      type: 'DateField',
      key: 'publishedDate',
      indexable: true,
    },
    {
      title: 'Hour',
      type: 'TextField',
      humanReadable: true,
      key: 'hour',
    },
    {
      title: 'Summary Content',
      type: 'RichText',
      humanReadable: true,
      key: 'summary',
      html: true,
    },
    {
      title: 'Modified Date',
      type: 'DateField',
      key: 'modifiedDate',
      indexable: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: [
        'SocialShare',
        'DownloadCollection',
        'BlogDistributor',
        'BasicText',
        'BasicContent',
      ],
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'BlogDetail',
    newsTitle: { content: 'Title', tag: 'h1' },
    entry: 'Lorem ipsum paragraph',
    hour: '00:00',
    publishedDate: getCurrentDate(),
    category: [],
    image: defaultBackgroundImage,
    body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur. ',
    summary: 'Lorem ipsum',
    modifiedDate: getCurrentDate(),
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [
        {
          component: 'SocialShare',
          elements: [{ ...SocialShare.default }],
        },
      ],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsNewsDetail,
    '2x': thumbnailsNewsDetail,
  },
}
