export default [
  {
    key: 'MainMenu',
    display: 'Main Menu',
  },
  {
    key: 'FooterMenu',
    display: 'Footer Menu',
  },
]
