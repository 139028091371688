import thumbnailsStaffList from '@griddo-images/thumbnails/StaffList.png'

import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { basicModuleWhiteList } from '@schemas/modules'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Staff List',
  component: 'StaffList',
  dataPacks: ['STAFF'],
  type: { label: 'Staff', value: 'staff', mode: 'list' },
  content: [
    {
      title: 'Use image as background',
      type: 'ConditionalField',
      key: 'conditionalBackground',
      mandatory: true,
      options: [
        {
          value: true,
          title: 'Yes',
          name: 'yes',
        },
        {
          value: false,
          title: 'No',
          name: 'no',
        },
      ],
      fields: [
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'backgroundImage',
          condition: true,
          helptext: 'Recommended size: 1920x1080',
        },
      ],
    },
    {
      title: 'Icon',
      type: 'ImageField',
      key: 'icon',
      hideable: true,
    },
    {
      title: 'Group Title',
      type: 'HeadingField',
      key: 'contentTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      humanReadable: true,
    },
    {
      title: 'Description',
      type: 'RichText',
      humanReadable: true,
      key: 'description',
      hideable: true,
      html: true,
    },
    {
      title: 'Items per page',
      type: 'NumberField',
      key: 'itemsPerPage',
      mandatory: true,
    },
    {
      title: 'No result rext',
      type: 'RichText',
      humanReadable: true,
      key: 'noResultsText',
      mandatory: true,
      html: true,
    },
    {
      title: '',
      type: 'ReferenceField',
      sources: [{ structuredData: 'STAFF' }],
      key: 'data',
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: basicModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'StaffList',
    conditionalBackground: false,
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 60,
    },
    icon: defaultBackgroundImage,
    contentTitle: { tag: 'h1', content: 'Staff List Title' },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ante elit, aliquam et justo non, finibus ultrices ex. Quisque in porttitor felis.',
    noResultsText:
      'Lo sentimos, no hemos encontrado resultados. Por favor, intenta una nueva búsqueda.',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData:'STAFF' }],
      order: 'alpha',
      quantity: 0,
    },
    itemsPerPage: 10,
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [],
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsStaffList,
    '2x': thumbnailsStaffList,
  },
}
