import thumbnailsHeroCarousel from '@griddo-images/thumbnails/HeroCarousel.png'

export default {
  schemaType: 'module',
  displayName: 'Hero Carousel',
  component: 'HeroCarousel',
  dataPacks: null,
  category: 'hero',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Cards',
          type: 'ComponentArray',
          elementUniqueSelection: false,
          mandatory: true,
          maxItems: null,
          key: 'elements',
          whiteList: [
            'BasicHeroElement',
            'HighlightedHeroElement',
            'BackgroundHeroElement',
            'VideoHeroElement',
          ],
        },
      ],
    },
  ],

  default: {
    component: 'HeroCarousel',
    elements: [],
  },

  thumbnails: {
    '1x': thumbnailsHeroCarousel,
    '2x': thumbnailsHeroCarousel,
  },
}
