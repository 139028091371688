import * as React from 'react'

const BasicTemplate = React.lazy(() =>
  import(
    './BasicTemplate' /* webpackChunkName: "template---src-BasicTemplate-js" */
  )
)
const Error404 = React.lazy(() =>
  import('./Error404' /* webpackChunkName: "template---src-Error404-js" */)
)
const Sitemap = React.lazy(() =>
  import('./Sitemap' /* webpackChunkName: "template---src-Sitemap-js" */)
)
const ProgramDetail = React.lazy(() =>
  import(
    './ProgramDetail' /* webpackChunkName: "template---src-ProgramDetail-js" */
  )
)
const StaffDetail = React.lazy(() =>
  import(
    './StaffDetail' /* webpackChunkName: "template---src-StaffDetail-js" */
  )
)
const StaffList = React.lazy(() =>
  import('./StaffList' /* webpackChunkName: "template---src-StaffList-js" */)
)
const LegalTemplate = React.lazy(() =>
  import(
    './LegalTemplate' /* webpackChunkName: "template---src-LegalTemplate-js" */
  )
)
const ApplyForm = React.lazy(() =>
  import('./ApplyForm' /* webpackChunkName: "template---src-ApplyForm-js" */)
)
const ThankYou = React.lazy(() =>
  import('./ThankYou' /* webpackChunkName: "template---src-ThankYou-js" */)
)
const ProgramList = React.lazy(() =>
  import(
    './ProgramList' /* webpackChunkName: "template---src-ProgramList-js" */
  )
)
const NewsDetail = React.lazy(() =>
  import('./NewsDetail' /* webpackChunkName: "template---src-NewsDetail-js" */)
)
const NewsList = React.lazy(() =>
  import('./NewsList' /* webpackChunkName: "template---src-NewsList-js" */)
)
const ContactTemplate = React.lazy(() =>
  import(
    './ContactTemplate' /* webpackChunkName: "template---src-ContactTemplate-js" */
  )
)
const EventDetail = React.lazy(() =>
  import(
    './EventDetail' /* webpackChunkName: "template---src-EventDetail-js" */
  )
)
const EventList = React.lazy(() =>
  import('./EventList' /* webpackChunkName: "template---src-EventList-js" */)
)
const ReportDetail = React.lazy(() =>
  import(
    './ReportDetail' /* webpackChunkName: "template---src-ReportDetail-js" */
  )
)
const ReportsList = React.lazy(() =>
  import(
    './ReportsList' /* webpackChunkName: "template---src-ReportsList-js" */
  )
)
const SearchResults = React.lazy(() =>
  import(
    './SearchResults' /* webpackChunkName: "template---src-SearchResults-js" */
  )
)
const GroupDetail = React.lazy(() =>
  import(
    './GroupDetail' /* webpackChunkName: "template---src-GroupDetail-js" */
  )
)
const ProjectDetail = React.lazy(() =>
  import(
    './ProjectDetail' /* webpackChunkName: "template---src-ProjectDetail-js" */
  )
)

const BlogDetail = React.lazy(() =>
  import('./BlogDetail' /* webpackChunkName: "template---src-BlogDetail-js" */)
)
const BlogList = React.lazy(() =>
  import('./BlogList' /* webpackChunkName: "template---src-BlogList-js" */)
)

const LandingProgram = React.lazy(() =>
  import(
    './LandingProgram' /* webpackChunkName: "template---src-LandingProgram-js" */
  )
)

const LandingMultiProgram = React.lazy(() =>
  import(
    './LandingMultiProgram' /* webpackChunkName: "template---src-LandingProgram-js" */
  )
)

export {
  BasicTemplate,
  Error404,
  Sitemap,
  StaffDetail,
  StaffList,
  LegalTemplate,
  ApplyForm,
  ThankYou,
  ProgramList,
  NewsDetail,
  NewsList,
  ContactTemplate,
  EventDetail,
  EventList,
  ReportDetail,
  ReportsList,
  SearchResults,
  GroupDetail,
  ProjectDetail,
  ProgramDetail,
  BlogDetail,
  BlogList,
  LandingProgram,
  LandingMultiProgram,
}
