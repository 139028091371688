import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Background Image',
  component: 'BackgroundImage',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        { title: 'File', type: 'ImageField', key: 'file' },
        { title: 'Veil percentage', type: 'SliderField', key: 'veil' },
        {
          type: 'SliderField',
          step: 1,
          minvalue: 0,
          maxvalue: 100,
          key: 'positionY',
          title: 'Vertical shift',
        },
        {
          type: 'SliderField',
          step: 1,
          minvalue: 0,
          maxvalue: 100,
          key: 'positionX',
          title: 'Horizontal shift',
        },
      ],
    },
  ],

  default: {
    component: 'BackgroundImage',
    file: defaultBackgroundImage,
    veil: 0,
    positionX: 50,
    positionY: 50,
  },
}
