import thumbnailsBackgroundHeroElement from '@griddo-images/thumbnails/BackgroundHeroElement.png'
import layoutsBackgroundHeroElementlayout002 from '@griddo-images/layouts/BackgroundHeroElement/layout002.png'
import layoutsBackgroundHeroElementlayout001 from '@griddo-images/layouts/BackgroundHeroElement/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Background Hero Element',
  component: 'BackgroundHeroElement',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: false,
          humanReadable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },

        {
          title: 'Link',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['LinkableImage'],
          key: 'backgroundImage',
          mandatory: true,
          hideable: true,
          helptext: 'Recommended size: 1920x1080',
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsBackgroundHeroElementlayout001,
            },
            {
              value: 'L002',
              img: layoutsBackgroundHeroElementlayout002,
            },
          ],
          columns: 2,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'BackgroundHeroElement',
    title: {
      content: 'Text',
      tag: 'h2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 60,
      positionX: 50,
      positionY: 50,
    },
    layout: 'L001',
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsBackgroundHeroElement,
    '2x': thumbnailsBackgroundHeroElement,
  },
}
