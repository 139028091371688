import AccordionElement from './AccordionElement'
import BasicCard from './BasicCard'
import ImageComponent from './ImageComponent'
import LinkableImage from './LinkableImage'
import VideoComponent from './VideoComponent'
import Button from './Button'
import CypherCard from './CypherCard'
import ProgramTabElement from './ProgramTabElement'
import BackgroundImage from './BackgroundImage'
import ProgramAccordion from './ProgramAccordion'
import ProgramAccordionElement from './ProgramAccordionElement'
import ProgramTable from './ProgramTable'
import ProgramTableColumn from './ProgramTableColumn'
import ProgramTableRow from './ProgramTableRow'
import DownloadElement from './DownloadElement'
import ProgramUsp from './ProgramUsp'
import ProgramTag from './ProgramTag'
import TestimonialCard from './TestimonialCard'
import VerticalTabElement from './VerticalTabElement'
import HorizontalTabElement from './HorizontalTabElement'
import ProgramQuickFact from './ProgramQuickFact'
import SecondaryColumnElement from './SecondaryColumnElement'
import MainColumnElement from './MainColumnElement'
import ImageCard from './ImageCard'
import BasicHeroElement from './BasicHeroElement'
import HighlightedHeroElement from './HighlightedHeroElement'
import BackgroundHeroElement from './BackgroundHeroElement'
import VideoHeroElement from './VideoHeroElement'
import LogoCard from './LogoCard'
import GridElement from './GridElement'
import BasicMoreInfo from './BasicMoreInfo'
import ProgramMoreInfo from './ProgramMoreInfo'
import ButtonRequestAdmission from './ButtonRequestAdmission'
import ProgramCard from '@schemas/components/ProgramCard'
import ProgramListCard from './ProgramListCard'
import Speaker from './Speaker'
import InteractiveCard from './InteractiveCard'
import Link from './Link'
import LandingQuickFact from './LandingQuickFact'
import LandingProgramCard from './LandingProgramCard'
import LandingProgramTabElement from './LandingProgramTabElement'
import LandingBasicMoreInfo from './LandingBasicMoreInfo'

export default {
  AccordionElement,
  BasicCard,
  ImageComponent,
  LinkableImage,
  VideoComponent,
  Button,
  CypherCard,
  BackgroundImage,
  ProgramAccordion,
  ProgramAccordionElement,
  ProgramTable,
  ProgramTableColumn,
  ProgramTableRow,
  ProgramTabElement,
  DownloadElement,
  ProgramUsp,
  ProgramTag,
  TestimonialCard,
  HorizontalTabElement,
  VerticalTabElement,
  SecondaryColumnElement,
  MainColumnElement,
  ProgramQuickFact,
  ImageCard,
  BasicHeroElement,
  HighlightedHeroElement,
  BackgroundHeroElement,
  VideoHeroElement,
  LogoCard,
  GridElement,
  BasicMoreInfo,
  ProgramMoreInfo,
  ButtonRequestAdmission,
  ProgramCard,
  ProgramListCard,
  Speaker,
  InteractiveCard,
  Link,
  LandingQuickFact,
  LandingProgramTabElement,
  LandingProgramCard,
  LandingBasicMoreInfo,
}
