import thumbnailsMixedCollection from '@griddo-images/thumbnails/MixedCollection.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Mixed Collection',
  component: 'MixedCollection',
  dataPacks: null,
  category: 'collections',
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Entry',
          type: 'TextField',
          humanReadable: true,
          key: 'entry',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Elements',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: 3,
          key: 'elements',
          whiteList: ['ImageCard'],
          helpText: 'Only a maximum of 3 cards will be shown',
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'MixedCollection',
    icon: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    entry: 'Lorem ipsum paragraph',
    detail:
      'Aenean a congue felis. Duis imperdiet urna sit amet odio lacinia faucibus. Suspendisse varius mauris nec tempor euismod. Quisque pharetra lorem sed sem tempor, accumsan consectetur leo varius. Aliquam at augue viverra, convallis ante vitae, tempor ex. Nunc elit elit, volutpat quis augue ut, malesuada auctor ante. Sed a rhoncus nibh, eleifend pellentesque lacus. Sed semper erat arcu, in tempor purus accumsan eu. Aliquam venenatis justo lacus, at mollis nisl venenatis id. Proin eget mattis tortor. Donec auctor justo quis auctor porta. Donec eget elit sollicitudin, congue urna eget, tristique sapien. Mauris lobortis enim id sapien convallis euismod.',
    kind: 'ImageCard',
    elements: [
      {
        component: 'ImageCard',
        image: defaultBackgroundImage,
        type: 'other',
        title: {
          content: 'Lorem ipsum',
          tag: 'h2',
        },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        url: {
          href: '',
          linkToURL: null,
          newTab: false,
          noFollow: false,
        },
      },
      {
        component: 'ImageCard',
        image: defaultBackgroundImage,
        type: 'other',
        title: {
          content: 'Lorem ipsum',
          tag: 'h2',
        },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        url: {
          href: '',
          linkToURL: null,
          newTab: false,
          noFollow: false,
        },
      },
      {
        component: 'ImageCard',
        image: defaultBackgroundImage,
        type: 'other',
        title: {
          content: 'Lorem ipsum',
          tag: 'h2',
        },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        url: {
          href: '',
          linkToURL: null,
          newTab: false,
          noFollow: false,
        },
      },
    ],
    theme: 'light',
  },

  thumbnails: {
    '1x': thumbnailsMixedCollection,
    '2x': thumbnailsMixedCollection,
  },
}
