import thumbnailsStaffDistributor from '@griddo-images/thumbnails/StaffDistributor.png'

export default {
  schemaType: 'module',
  displayName: 'Staff Distributor',
  component: 'StaffDistributorSimple',
  category: 'staff',
  dataPacks: ['STAFF'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'STAFF' }],
          key: 'data',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Distributor type',
          key: 'distributorType',
          type: 'ConditionalField',
          options: [
            {
              name: 'expandable',
              value: 'expandable',
              title: 'Expandable',
            },
            {
              name: 'fully',
              value: 'fully',
              title: 'Fully Visible Distributor',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'StaffDistributorSimple',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData: 'STAFF' }],
      order: 'recent',
      quantity: 4,
    },
    distributorType: 'expandable',
  },

  thumbnails: {
    '1x': thumbnailsStaffDistributor,
    '2x': thumbnailsStaffDistributor,
  },
}
