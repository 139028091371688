import thumbnailsStaffDistributor from '@griddo-images/thumbnails/StaffDistributor.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import layoutsStaffDistributorlayoutCentered from '@griddo-images/layouts/StaffDistributor/layoutCentered.png'
import layoutsStaffDistributorlayoutAlignLeft from '@griddo-images/layouts/StaffDistributor/layoutAlignLeft.png'

import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'link'
defaultBtn.buttonText = 'Ver más'

export default {
  schemaType: 'module',
  displayName: 'Staff Distributor',
  component: 'StaffDistributor',
  category: 'staff',
  dataPacks: ['STAFF'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          humanReadable: true,
        },
        {
          title: 'Entry',
          type: 'TextField',
          humanReadable: true,
          key: 'entry',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          humanReadable: true,
          key: 'detail',
          hideable: true,
          html: true,
        },

        {
          title: 'Elements',
          type: 'ReferenceField',
          sources: [{ structuredData: 'STAFF' }],
          key: 'data',
          mandatory: true,
        },

        {
          title: 'Grouping Link',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'layoutAlignLeft',
              img: layoutsStaffDistributorlayoutAlignLeft,
            },
            {
              value: 'layoutCentered',
              img: layoutsStaffDistributorlayoutCentered,
            },
          ],
          columns: 2,
        },

        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
          ],
          columns: 8,
        },

        {
          title: 'Distributor type',
          key: 'distributorType',
          type: 'ConditionalField',
          options: [
            {
              name: 'expandable',
              value: 'expandable',
              title: 'Expandable',
            },
            {
              name: 'fully',
              value: 'fully',
              title: 'Fully Visible Distributor',
            },
          ],
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'StaffDistributor',
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    entry:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{structuredData: 'STAFF' }],
      order: 'recent',
      quantity: 6,
    },
    line: true,
    layout: 'layoutCentered',
    theme: 'light',
    button: { ...defaultBtn },
    distributorType: 'expandable',
  },

  thumbnails: {
    '1x': thumbnailsStaffDistributor,
    '2x': thumbnailsStaffDistributor,
  },
}
