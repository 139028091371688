import thumbnailsHighlightedContent from '@griddo-images/thumbnails/HighlightedContent.png'
import layoutsHighlightedContentlayout002 from '@griddo-images/layouts/HighlightedContent/layout002.png'
import layoutsHighlightedContentlayout001 from '@griddo-images/layouts/HighlightedContent/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Highlighted Content',
  component: 'HighlightedContent',
  category: 'content',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'backgroundImage',
          hideable: true,
          helptext: 'Image adaptable to the layout',
        },
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          condition: false,
          humanReadable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          humanReadable: true,
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'L001',
              img: layoutsHighlightedContentlayout001,
            },
            {
              value: 'L002',
              img: layoutsHighlightedContentlayout002,
            },
          ],
          columns: 2,
        },
      ],
    },
  ],

  default: {
    component: 'HighlightedContent',
    icon: defaultBackgroundImage,
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 0,
    },
    title: {
      content: 'Text',
      tag: 'h2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    layout: 'L001',
  },

  thumbnails: {
    '1x': thumbnailsHighlightedContent,
    '2x': thumbnailsHighlightedContent,
  },
}
