import thumbnailsSearchResults from '@griddo-images/thumbnails/SearchResults.png'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'

import { basicModuleWhiteList } from '@schemas/modules'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Search Results',
  component: 'SearchResults',
  dataPacks: ['CONTENT'],
  type: { label: 'Content', value: 'content' },

  content: [
    {
      title: 'Search Results Title',
      type: 'HeadingField',
      key: 'searchResultsTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      humanReadable: true,
    },
    {
      title: 'Icon',
      type: 'ImageField',
      key: 'icon',
      hideable: true,
    },
    {
      title: 'Description',
      type: 'RichText',
      humanReadable: true,
      key: 'description',
      html: true,
      hideable: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: basicModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'SearchResults',
    searchResultsTitle: { content: 'Resultados de Búsqueda', tag: 'h1' },
    icon: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing.',
    noResultsText:
      'Lo sentimos, no hemos encontrado resultados. Inténtalo con otra búsqueda diferente.',
    mainSection: {
      component: 'Section',
      name: 'Related Content',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsSearchResults,
    '2x': thumbnailsSearchResults,
  },
}
